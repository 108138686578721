import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'use-breakpoint';
import { CountryPicker, Link as CustomLink } from '@/core';
import { Logo } from '@/lib/assets/svg';
import { setLanguageFormat, setStep } from '@/lib/store/config';
import * as S from './styles';

const countries = ['BR', 'ES', 'US'];

const BREAKPOINTS = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
};

const Header = () => {
  const dispatch = useDispatch();
  const { step } = useSelector(({ config }) => config);
  const handleCallback = value => {
    dispatch(setLanguageFormat(value));
  };

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

  useEffect(() => {
    window.onbeforeunload = e => {
      if (step === 'step1' || step === 'step2' || step === 'step3') {
        e.preventDefault();
      }
    };
  }, [step]);
  return (
    <S.Wrapper
      className={breakpoint === 'mobile' ? 'wrapper-confirmation' : ''}
    >
      <S.SuperiorContainer>
        <S.LogoContainer className="container-logo container-logo-confirmation">
          <CustomLink onClick={() => dispatch(setStep('register'))}>
            <Logo />
          </CustomLink>
        </S.LogoContainer>
        <S.CountryPickerContainer>
          <span data-test="select_language">
            <CountryPicker callback={handleCallback} list={countries} />
          </span>
        </S.CountryPickerContainer>
      </S.SuperiorContainer>
    </S.Wrapper>
  );
};

export default Header;
