import { Grid, Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 300px;
      ${mixins.animation('fade-in', '0.2s')};
      h1 {
        &.ant-typography {
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          width: 159px;
        }
      }
      .form-radio {
        margin: 0;
        .ant-form-item-label ~ .ant-form-item-control {
          display: none;
        }
        .ant-form-item-control,
        .form-item-equipment {
          flex-direction: row;
          justify-content: flex-end;
        }
        & > .ant-form-item-control {
          .ant-form-item-control-input {
            align-items: baseline;
            .ant-form-item-control-input-content {
              display: flex;
              justify-content: flex-end;
              label {
                &.ant-radio-wrapper-in-form-item {
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }
      label {
        svg {
          fill: ${theme.primaryColor};
          width: 14px;
          height: 14px;
        }
      }
      .select-ddi {
        border: 0;
        div.ant-select-selector {
          border: 0;
          padding: 0;
          height: 27px;
          width: 56px !important;
          span.ant-select-selection-item {
            font-size: 22px;
            border-radius: 8px;
            width: 60px;
          }
          div {
            svg {
              width: 70%;
            }
          }
        }
        div.ant-select-selector ~ .ant-select-arrow {
          right: 2px;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
`;

export const StepContainer = styled.div`
  margin-bottom: 40px;
`;

export const Section = styled.div`
  padding-bottom: 40px;
`;

export const Info = styled(Text)`
  ${() => {
    return css`
      font-size: 16px;
    `;
  }}
`;

export const TextGuidLine = styled(Text)`
  ${() => {
    return css`
      font-size: 12px;
      font-weight: 600;
      &.ant-typography {
        margin-bottom: 0;
      }
    `;
  }}
`;

export const GridPassword = styled(Grid)`
  ${() => {
    return css`
      .ant-form-item {
        margin-bottom: 0;
        .ant-form-item-control-input-content {
          .ant-typography {
            display: none;
          }
        }
      }
      & > div {
        ul {
          margin-bottom: 24px;
        }
      }
    `;
  }}
`;

export const GridHasDisability = styled(Grid)`
  margin-bottom: 24px;
  .ant-col .ant-form-item-control {
    justify-content: flex-start;
  }
  #has_disability {
    display: flex;
    flex-direction: column;
  }
`;
