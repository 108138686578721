import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Field,
  HasDisability,
  ErrorBoundary,
  HelperPassword,
} from '@/components';
import { Button, Form, Grid, Input, Radio, Select, Text, Steps } from '@/core';
import { InfoIcon, Sparkles } from '@/lib/assets/svg';
import { Options, getSteps } from '@/lib/enum';
import Response from '@/lib/model/api/response';
import {
  useGetCountryQuery,
  useGetEthnicitiesMutation,
  useGetGenderMutation,
  useGetMaritalMutation,
} from '@/lib/service/autocomplete';
import { useStep1Mutation, useSearchMutation } from '@/lib/service/register';
import { setStep } from '@/lib/store/config';
import { setForm } from '@/lib/store/step1';
import { step1 as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import TooltipContent from '../tooltipContent';
import * as S from './styles';

const Step1 = () => {
  const { t, i18n } = useTranslation();
  const items = getSteps();

  const dispatch = useDispatch();

  const [errorMessage, setErrorMessage] = useState('');

  const {
    authorization,
    errors: errorsState,
    ...state
  } = useSelector(({ step1, errors, config: { authorization } }) => ({
    ...step1,
    authorization,
    errors,
  }));

  let birth_date = [];
  if (state.birth_date) {
    birth_date = state.birth_date.split('-');
  }

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    resetField,
    setError,
    getFieldState,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
      birth_date: birth_date.length
        ? `${birth_date[2]}/${birth_date[1]}/${birth_date[0]}`
        : undefined,
      disability: undefined,
      ethnicity: undefined,
      gender: undefined,
      has_disability: state.has_disability ? 1 : 0,
      has_medical_report: state.has_medical_report ? 1 : 0,
      marital_status: undefined,
      uses_device: state.uses_device ? 1 : 0,
    },
  });

  const [
    updateMaritalStatus,
    { data: maritalStatus, isLoading: maritalStatusLoading },
  ] = useGetMaritalMutation();

  const [updateEthnicity, { data: ethnicity, isLoading: ethnicityLoading }] =
    useGetEthnicitiesMutation();

  const [updateGender, { data: gender, isLoading: genderLoading }] =
    useGetGenderMutation();

  useEffect(() => {
    updateMaritalStatus({ lang: i18n.language }).then(() => {
      if (state.marital_status) {
        setValue('marital_status', Number(state.marital_status));
        trigger(['marital_status', 'password']);
      }
    });

    updateEthnicity({ lang: i18n.language }).then(() => {
      if (state.ethnicity) {
        setValue('ethnicity', Number(state.ethnicity));
        trigger(['ethnicity', 'password']);
      }
    });

    updateGender({ lang: i18n.language }).then(() => {
      if (state.gender) {
        setValue('gender', state.gender);
        trigger(['gender', 'password']);
      }
    });

    Object.entries(errorsState)
      .filter(([, { step, hasError }]: any) => step === 1 && hasError)
      .forEach(([key]) => {
        setError(
          key,
          {
            message: `error.${key}.apiError`,
            type: 'apiError',
          },
          {
            shouldFocus: true,
          },
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const { data: country } = useGetCountryQuery();

  const [step1, { isLoading, isError, error: step1Error }] = useStep1Mutation();
  const [updateSearch, { isLoading: documentLoading }] = useSearchMutation();

  const radioOptions = Options.map(item => ({
    ...item,
    label: t(`commons.${item.label}`),
  }));

  const onSubmit = async payload => {
    const { birth_date } = payload;

    const data = {
      ...payload,
      birth_date: format(birth_date, 'yyyy-MM-dd'),
      lang: i18n.language,
    };

    dispatch(setForm(data));
    step1({
      ...data,
      authorization,
    }).then((response: Response) => {
      if (!response.error) {
        dispatch(setStep('step2'));
      }
    });
  };

  const foreign = ![undefined, 'BRA'].includes(watch('nationality'));
  const mustDisplayFields = !['ARG', 'COL'].includes(watch('nationality'));

  useEffect(() => {
    if (!mustDisplayFields) {
      setValue('ethnicity', 1);
      setValue('gender', 'n');
    }
  }, [mustDisplayFields, setValue]);

  const searchDocument = ({ target: { value } }) => {
    setValue('document', value, {
      shouldValidate: true,
      shouldDirty: true,
    });
    const document = !foreign ? value.replace(/\D/g, '') : value;
    setTimeout(() => {
      const { error } = getFieldState('document');
      if (((!foreign && document.length === 11) || foreign) && !error) {
        updateSearch({
          authorization,
          type: 'document',
          value: document,
        }).then((response: Response) => {
          const status = response?.error?.status;
          if (status === 409) {
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
              const data = response?.error?.data;
              const {
                errors: [{ data: responseError }],
              } = data;
              setErrorMessage(
                t('api.error.document.registered', {
                  ...responseError,
                }),
              );
              setTimeout(() => {
                setErrorMessage('');
              }, 3000);
            } catch {}
          }
        });
      }
    }, 1);
  };

  return (
    <S.Wrapper>
      <S.StepContainer>
        <Steps current={0} items={items} />
      </S.StepContainer>
      <S.Title>
        <Text textType="title" level={1} data-test="title-step">
          {t('step1.title')}
        </Text>
      </S.Title>
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <S.Section>
          <Grid type="row">
            <Grid type="col" span="24">
              <Form type="item" label={t('step1.form.full_name')}>
                <Field
                  name="full_name"
                  control={control}
                  Component={Input}
                  errors={errors}
                />
              </Form>
            </Grid>
            <S.GridPassword type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step1.form.password')}>
                <Field
                  name="password"
                  control={control}
                  Component={Input}
                  compProps={{
                    type: 'password',
                    ...(errors.password?.type && {
                      error: t(`error.password.${errors.password?.type}`),
                    }),
                    visibilityToggle: {
                      visible: true,
                    },
                  }}
                  errors={errors}
                  withPlaceholder={false}
                />
              </Form>
              <S.TextGuidLine>{t(`error.password.guidelines`)}</S.TextGuidLine>
              <HelperPassword password={watch('password')} />
            </S.GridPassword>
          </Grid>
          <Grid type="row">
            <Grid type="col" span="24">
              <Form type="item" label={t('step1.form.nationality')}>
                <Field
                  name="nationality"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: country,
                    filterOption: (inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1,
                    showSearch: true,
                    onSelect: () => {
                      resetField('document', {
                        keepError: true,
                        defaultValue: '',
                      });
                    },
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid
              type="col"
              xs={{ span: 24 }}
              lg={{ span: mustDisplayFields ? 11 : 24 }}
            >
              <Form
                type="item"
                label={t(
                  `step1.form.${foreign ? 'document_foreigner' : 'document'}`,
                )}
                name="document"
                tooltip={
                  foreign && {
                    title: (
                      <TooltipContent
                        title={t('step1.form.tooltip.document.question')}
                        content={t('step1.form.tooltip.document.answer')}
                      />
                    ),
                    icon: (
                      <span
                        role="img"
                        aria-label="info-circle"
                        className="anticon anticon-info-circle ant-form-item-tooltip"
                      >
                        <InfoIcon />
                      </span>
                    ),
                    trigger: ['hover', 'click'],
                  }
                }
              >
                <Field
                  name="document"
                  control={control}
                  Component={Input}
                  compProps={{
                    ...(!foreign && {
                      type: 'mask',
                      mask: '000.000.000-00',
                    }),
                    placeholder: t(
                      `placeholder.${
                        foreign ? 'document_foreigner' : 'document'
                      }`,
                    ),
                    maxLength: 63,
                  }}
                  onBlurCallback={searchDocument}
                  errors={errors}
                  withPlaceholder={false}
                />
              </Form>
            </Grid>
            <Grid
              type="col"
              xs={{ span: 24 }}
              lg={{
                span: mustDisplayFields ? 12 : 11,
                offset: mustDisplayFields ? 1 : 0,
              }}
            >
              <Form type="item" label={t('step1.form.birth_date')}>
                <Field
                  name="birth_date"
                  control={control}
                  Component={Input}
                  compProps={{
                    type: 'mask',
                    mask: '00/00/0000',
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            {mustDisplayFields && (
              <Grid type="col" xs={{ span: 24 }} lg={{ span: 11, offset: 0 }}>
                <Form
                  type="item"
                  label={t('step1.form.gender')}
                  tooltip={{
                    title: (
                      <TooltipContent
                        title={t('step1.form.tooltip.common.question')}
                        content={t('step1.form.tooltip.common.answer')}
                        emotion={[<Sparkles />]}
                      />
                    ),
                    icon: (
                      <span
                        role="img"
                        aria-label="info-circle"
                        className="anticon anticon-info-circle ant-form-item-tooltip"
                      >
                        <InfoIcon />
                      </span>
                    ),
                    trigger: ['hover', 'click'],
                  }}
                >
                  <Field
                    name="gender"
                    control={control}
                    Component={Select}
                    compProps={{
                      options: gender,
                      placeholder: genderLoading
                        ? t('commons.loading')
                        : t('placeholder.gender'),
                    }}
                    errors={errors}
                  />
                </Form>
              </Grid>
            )}
            <Grid type="col" xs={{ span: 24 }} lg={{ span: 12, offset: 1 }}>
              <Form type="item" label={t('step1.form.marital_status')}>
                <Field
                  name="marital_status"
                  control={control}
                  Component={Select}
                  compProps={{
                    isLoading: maritalStatusLoading,
                    placeholder: maritalStatusLoading
                      ? t('commons.loading')
                      : t('placeholder.marital_status'),
                    options: maritalStatus,
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            {mustDisplayFields && (
              <Grid type="col" xs={{ span: 24 }}>
                <Form
                  type="item"
                  label={t('step1.form.ethnicity')}
                  tooltip={{
                    title: (
                      <TooltipContent
                        title={t('step1.form.tooltip.common.question')}
                        content={t('step1.form.tooltip.common.answer')}
                        emotion={[<Sparkles />]}
                      />
                    ),
                    icon: (
                      <span
                        role="img"
                        aria-label="info-circle"
                        className="anticon anticon-info-circle ant-form-item-tooltip"
                      >
                        <InfoIcon />
                      </span>
                    ),
                    trigger: ['hover', 'click'],
                  }}
                >
                  <Field
                    name="ethnicity"
                    control={control}
                    Component={Select}
                    compProps={{
                      options: ethnicity,
                      placeholder: ethnicityLoading
                        ? t('commons.loading')
                        : t('placeholder.ethnicity'),
                    }}
                    errors={errors}
                  />
                </Form>
              </Grid>
            )}
            {mustDisplayFields && (
              <Grid type="col" span={24} order={4}>
                <Grid type="row" span={24}>
                  <Form
                    type="item"
                    label={t('step1.form.has_disability')}
                    className="form-radio"
                    tooltip={{
                      title: (
                        <TooltipContent
                          title={t('step1.form.tooltip.common.question')}
                          content={t('step1.form.tooltip.common.answer')}
                          emotion={[<Sparkles />]}
                        />
                      ),
                      icon: (
                        <span
                          role="img"
                          aria-label="info-circle"
                          className="anticon anticon-info-circle ant-form-item-tooltip"
                        >
                          <InfoIcon />
                        </span>
                      ),
                      trigger: ['hover', 'click'],
                    }}
                  />
                </Grid>
                <S.GridHasDisability type="row" span={24}>
                  <Grid type="col" span={6}>
                    <Form
                      valuePropName="checked"
                      type="item"
                      className="form-radio"
                    >
                      <Field
                        Component={Radio}
                        compProps={{
                          onChange: e =>
                            setValue('has_disability', e.target.checked, {
                              shouldValidate: true,
                              shouldDirty: true,
                            }),
                          options: radioOptions,
                        }}
                        control={control}
                        errors={errors}
                        name="has_disability"
                      />
                    </Form>
                  </Grid>
                </S.GridHasDisability>
                {watch('has_disability') === 1 && (
                  <HasDisability
                    control={control}
                    errors={errors}
                    options={radioOptions}
                    setValue={setValue}
                    state={state}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </S.Section>
        <Button
          block
          loading={isLoading || documentLoading}
          type="primary"
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          data-test="submit_step1_button"
        >
          {documentLoading
            ? t('step1.form.searching_document')
            : t('commons.next')}
        </Button>
      </Form>
      {isError && <ErrorBoundary errors={step1Error} />}
      {!!errorMessage && (
        <ErrorBoundary type="warning" message={errorMessage} />
      )}
    </S.Wrapper>
  );
};

export default Step1;
