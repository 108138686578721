/* eslint no-console: ["error", { allow: ["log"] }] */
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Field, ErrorBoundary } from '@/components';
import { Text, Button, Input, Form, Grid } from '@/core';
import { Email as IconEmail } from '@/lib/assets/svg';
import Response from '@/lib/model/api/response';
import { useRequestActivationMutation } from '@/lib/service/email';
import { reactive as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const SubTitle = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text className="ant-result-subtitle-text">
        {t('reactivate_account.result_subtitle')}
      </Text>
      <Text className="ant-result-subtitle-email-title">
        <b>{t('reactivate_account.title_email')}</b>
      </Text>
    </>
  );
};

const ReactivateAccount = () => {
  const { t, i18n } = useTranslation();
  const state = useSelector(({ reactive }) => reactive);
  const [sendEmail, setSendEmail] = useState(false);
  const [
    reactivateAccount,
    { isLoading, isError, error: reactiveAccountError },
  ] = useRequestActivationMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = async data => {
    reactivateAccount(data).then((response: Response) => {
      if (!response.error) {
        setSendEmail(true);
      }
    });
  };

  useEffect(() => {
    setValue('lang', i18n.language);
  }, [i18n.language, setValue]);

  return (
    <S.Wrapper>
      <Text textType="title" level={1} style={{ marginTop: 40 }}>
        {!sendEmail
          ? t('reactivate_account.title')
          : t('reactivate_account.result_title')}
      </Text>
      {!sendEmail ? (
        <Grid type="col">
          <S.Subtitle>
            <Text>{t('reactivate_account.subtitle')}</Text>
          </S.Subtitle>
          <Form
            layout="vertical"
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Form type="item" label={t('reactivate_account.email')}>
              <Field
                Component={Input}
                control={control}
                errors={errors}
                name="identity"
                compProps={{
                  placeholder: t('placeholder.identity_recovery_password'),
                }}
              />
            </Form>
            <Button
              block
              loading={isLoading}
              size="large"
              type="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
            >
              {t('commons.send')}
            </Button>
          </Form>
        </Grid>
      ) : (
        <Grid type="col">
          <S.Result icon={<IconEmail />} subTitle={<SubTitle />} />
          <S.Info>
            <Text>
              {t('email.info')}&nbsp;
              <Button type="link" onClick={() => setSendEmail(false)}>
                {t('email.resend')}
              </Button>
            </Text>
          </S.Info>
        </Grid>
      )}
      {isError && <ErrorBoundary errors={reactiveAccountError} />}
    </S.Wrapper>
  );
};

export default ReactivateAccount;
