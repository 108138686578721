import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary, Field } from '@/components';
import { Text, Button, Input, Form } from '@/core';
import { Trophy } from '@/lib/assets/svg';
import Response from '@/lib/model/api/response';
import { useRemoveMfaMutation } from '@/lib/service/authenticate';
import { setForm } from '@/lib/store/authenticate';
import { setStep } from '@/lib/store/config';
import { removeMfa as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const SubtTitleText = () => {
  const { t } = useTranslation();
  return (
    <Text className="ant-result-subtitle-text">
      {t('remove_mfa.result_message')}
    </Text>
  );
};

const timeout = 5000;

const RemoveMfa = () => {
  const dispatch = useDispatch();
  const state = useSelector(({ login }) => login);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [removeMfa, { isLoading }] = useRemoveMfaMutation();

  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const [count, setCount] = useState(timeout / 1000);

  useEffect(() => {
    if (showSuccess) {
      if (count > 0) {
        setInterval(() => setCount(count - 1), 1000);
      }
      setTimeout(() => {
        dispatch(setStep('login'));
      }, timeout);
    }
  }, [showSuccess, count, dispatch]);

  const setMessage = message => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const onSubmit = async data => {
    dispatch(setForm(data));
    removeMfa(data).then((response: Response) => {
      if (!response.error) {
        setShowSuccess(true);
      } else {
        setMessage(t('remove_mfa.api_error'));
      }
    });
  };

  return (
    <S.Wrapper>
      {!showSuccess ? (
        <>
          <Text textType="title" level={1} style={{ marginTop: 40 }}>
            {t('remove_mfa.title')}
          </Text>
          <S.Subtitle>
            <Text>{t('remove_mfa.subtitle')}</Text>
          </S.Subtitle>
          <Form
            layout="vertical"
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Form type="item" label={t('remove_mfa.email')}>
              <Field
                Component={Input}
                control={control}
                errors={errors}
                name="email"
                withPlaceholder={false}
              />
            </Form>
            <Form type="item" label={t('remove_mfa.document')}>
              <Field
                Component={Input}
                control={control}
                errors={errors}
                name="document"
                withPlaceholder={false}
              />
            </Form>
            <Form type="item" label={t('remove_mfa.zipcode')}>
              <Field
                Component={Input}
                control={control}
                errors={errors}
                name="zipcode"
                withPlaceholder={false}
              />
            </Form>
            <Button
              block
              disabled={isLoading || !isValid}
              loading={isLoading}
              size="large"
              type="primary"
              onClick={handleSubmit(onSubmit)}
            >
              {t('remove_mfa.button')}
            </Button>
          </Form>
        </>
      ) : (
        <>
          <Text textType="title" level={1} style={{ marginTop: 40 }}>
            {t('remove_mfa.congrats')}
          </Text>
          <S.Result icon={<Trophy />} subTitle={<SubtTitleText />} />
          <S.Text>
            {`${t('confirmation.wait')} ${t('commons.second', { count })} ${t(
              'confirmation.click_below',
            )}`}
          </S.Text>
        </>
      )}
      {!!errorMessage && <ErrorBoundary message={errorMessage} />}
    </S.Wrapper>
  );
};

export default RemoveMfa;
