import React, { FC, VFC } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Popover } from '@/core';

interface FieldProps {
  Component:
    | FC<ComponentProps>
    | VFC<ComponentProps>
    | React.FunctionComponent<ComponentProps>;
  compProps?: Record<string, any>;
  contentPopover?: any;
  control: any;
  errors: any;
  name: string;
  withPopover?: boolean;
  titlePopover?: string;
  onBlurCallback?: any;
  onChangeCallback?: any;
  valuePropName?: any;
  withPlaceholder?: boolean;
}

interface ComponentProps {
  error?: string;
  id?: string;
  onBlur?: any;
  onChange?: any;
  placeholder?: string;
  status?: string;
}

const Field: FC<FieldProps> = ({
  children = null,
  Component,
  contentPopover,
  compProps = {},
  control,
  errors,
  withPopover = false,
  name,
  onBlurCallback = undefined,
  onChangeCallback = undefined,
  titlePopover = '',
  withPlaceholder = true,
}) => {
  const props = errors[name]?.message;
  const error =
    typeof props === 'object' ? errors[name]?.message?.error : props;

  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, ...field } }) => (
        <div>
          {withPopover ? (
            <Popover
              content={contentPopover}
              title={titlePopover}
              trigger="focus"
            >
              <Component
                error={t(error, { ...props })}
                id={name}
                placeholder={
                  (withPlaceholder && t(`placeholder.${name}`)) || ''
                }
                status={errors[name]?.message && 'error'}
                {...compProps}
                {...field}
                onChange={event =>
                  onChangeCallback ? onChangeCallback(event) : onChange(event)
                }
                onBlur={event =>
                  onBlurCallback ? onBlurCallback(event) : onBlur()
                }
              >
                {children}
              </Component>
            </Popover>
          ) : (
            <Component
              error={t(error, { ...props })}
              id={name}
              placeholder={(withPlaceholder && t(`placeholder.${name}`)) || ''}
              status={errors[name]?.message && 'error'}
              {...compProps}
              {...field}
              onChange={event =>
                onChangeCallback ? onChangeCallback(event) : onChange(event)
              }
              onBlur={event =>
                onBlurCallback ? onBlurCallback(event) : onBlur()
              }
            >
              {children}
            </Component>
          )}
        </div>
      )}
    />
  );
};

export default Field;
