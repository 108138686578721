import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  width: 300px;
  margin: 56px auto 40px;
  .ant-row {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    width: 480px;
  }
  @media only screen and (min-width: 1024px) {
    margin: 64px auto 56px;
    &.wrapper-confirmation {
      justify-content: flex-end;
    }
  }
`;

export const SuperiorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: nowrap;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    justify-content: flex-end;
  }
`;

export const CountryPickerContainer = styled.div`
  ${({ theme }) => {
    return css`
      ${mixins.animation('fade-in', '0.2s')};
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 300px;

      .label-country-picker {
        padding-bottom: 0;
        font-size: ${theme.fontSizeLabel};
        font-weight: 400;
        color: ${theme.gray8};
        padding-right: 8px;
        display: none;
      }
      .ant-select {
        .ant-select-selector {
          padding-left: 0 !important;
          width: 120px;
        }
        .ant-select-selection-item {
          font-size: 28px;
          & > div {
            justify-content: space-around;
          }
        }
        .ant-select-arrow {
          margin-top: -2px;
          right: 0;
        }
      }
      .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
          box-shadow: 0 0 0 2px rgba(7, 91, 58, 0.2);
        }
      }
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
        background-color: ${theme.greenBackground};
      }
      .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        padding-right: 8px;
      }
      @media only screen and (min-width: 768px) {
        .label-country-picker {
          display: inline-block;
        }
        width: 480px;
        padding: 0 16px;
      }
    `;
  }}
`;

export const LogoContainer = styled.div`
  ${({ theme }) => {
    return css`
      ${mixins.animation('fade-in', '0.2s')};
      display: flex;
      margin-left: 0;
      width: 50%;
      .ant-btn {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin: 0;
        span {
          display: flex;
          align-items: center;
          svg {
            margin-right: 4px;
          }
        }
      }
      &.container-logo {
        img {
          width: 123px;
          height: 30px;
        }
        &-confirmation {
          margin-top: 0;
        }
      }
      @media only screen and (min-width: 768px) {
        &.container-logo {
          img {
            width: 158px;
            height: 62px;
          }
          &-confirmation {
            margin-top: 0;
          }
        }
      }
      @media only screen and (min-width: 1024px) {
        &.container-logo {
          display: none;
        }
        .ant-btn-link {
          align-items: center;
          display: flex;
        }
        svg {
          margin-right: 10px;
          stroke: ${theme.linkColor};
          width: 24px;
        }
      }
    `;
  }}
`;
