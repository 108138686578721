import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from '@/components';
import { Form, Text, Button, Input } from '@/core';
import { BrokenLink } from '@/lib/assets/svg';
import { setForm } from '@/lib/store/resend';
import { resend as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const SubTitle = () => {
  const { t } = useTranslation();
  return (
    <Text>
      {t('resend.message1')}
      <br />
      {t('resend.message2')}
    </Text>
  );
};

const Resend = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const state = useSelector(({ resend }) => ({
    ...resend,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = data => {
    setValue('lang', i18n.language);
    dispatch(setForm(data));
  };

  return (
    <S.Wrapper>
      <Text textType="title" level={1} style={{ marginTop: 40 }}>
        {t('resend.title')}
      </Text>
      <S.Result icon={<BrokenLink />} subTitle={<SubTitle />} />
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Form type="item" label={t('resend.input')}>
          <Field
            Component={Input}
            compProps={{
              value: state.value,
            }}
            control={control}
            errors={errors}
            name="email"
          />
        </Form>
        <Button
          block
          size="large"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {t('commons.send')}
        </Button>
      </Form>
    </S.Wrapper>
  );
};

export default Resend;
