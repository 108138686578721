import { Result as ResultCore, Link as LinkCore } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme, loading }) => {
    return css`
      margin: 40px auto;
      width: 80%;

      h1 {
        &.ant-typography {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
        }
      }
      div.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        display: inline-flex;
        font-size: ${theme.fontSizeP};
        line-height: 20px;
        font-weight: 400;
        font-family: ${theme.fontFamily}, sans-serif;
        color: #525960;
        margin-bottom: 0;
      }
      ${loading ? `& > div { margin: 40px 10%}` : ``}
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
        ${loading ? `& > div { margin: 40px 30%}` : ``}
      }
      @media only screen and (min-width: 1024px) {
        ${loading ? `& > div { margin: 40px auto}` : ``}
      }
    `;
  }}
`;
export const Info = styled.div`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSizeP};
      line-height: 20px;
      font-weight: 400;
      font-family: ${theme.fontFamily}, sans-serif;
      color: ${theme.gray8};
      div.ant-typography {
        margin-bottom: 0;
        ${mixins.animation('fade-in', '0.2s')};
        padding-bottom: 24px;
      }
      .ant-btn-link {
        line-height: 18px;
      }
    `;
  }}
`;

export const Result = styled(ResultCore)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      margin: 40px 0;
      padding: 24px;
      border-radius: ${theme.borderRadius};
      .ant-result-icon {
        margin-bottom: 0;
        ${mixins.animation('bounce-top', '1s')};
        svg {
          &.svg-inline--fa {
            height: 56px;
          }
        }
      }
      .ant-result-subtitle-text {
        padding: 16px 0;
      }
    `;
  }}
`;

export const Link = styled(LinkCore)`
  ${({ theme }) => {
    return css`
      ${mixins.animation('fade-in', '0.2s')};
      display: flex;
      font-size: ${theme.fontSizeP};
      line-height: 20px;
      font-weight: 400;
      font-family: ${theme.fontFamily}, sans-serif;
    `;
  }}
`;
