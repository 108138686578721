import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, ErrorBoundary } from '@/components';
import {
  Button,
  Form,
  Grid,
  Input,
  Modal,
  Select,
  Steps,
  Table,
  Text,
  Tooltip,
} from '@/core';
import dataLayerPush from '@/lib/dataLayer';
import { States as enum_states, getSteps } from '@/lib/enum';
import Response from '@/lib/model/api/response';
import {
  useGetCitiesMutation,
  useGetCountryQuery,
  useGetStateMutation,
} from '@/lib/service/autocomplete';
import { useStep3Mutation } from '@/lib/service/register';
import {
  useGetAddressMutation,
  useGetZipCodeMutation,
} from '@/lib/service/search';
import { setStep } from '@/lib/store/config';
import { setConfirmation } from '@/lib/store/confirmation';
import { setErrors } from '@/lib/store/errors';
import { setForm } from '@/lib/store/step3';
import fields from '@/lib/utils/map-fields';
import { step3 as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const Step3 = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const items = getSteps();
  const [showModal, setShowModal] = useState(false);
  const { data: countries, isLoading: loadingCountries } = useGetCountryQuery();

  const [
    updateStates,
    { reset: resetStates, data: states, isLoading: loadingStates },
  ] = useGetStateMutation();

  const [
    updateCities,
    { reset: resetCities, data: cities, isLoading: loadingCities },
  ] = useGetCitiesMutation();

  const [updateAddress, { data: adressess, isLoading: loadingAdresses }] =
    useGetAddressMutation();

  const [updateZipCode] = useGetZipCodeMutation();

  const [step3, { isLoading, isError, error: step3Error }] = useStep3Mutation();

  let timer;

  const {
    authorization,
    errors: errorsState,
    ...state
  } = useSelector(({ step3, errors, config: { authorization } }) => ({
    ...step3,
    authorization,
    errors,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    resetField,
    setValue,
    setError,
    trigger,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const onSubmit = async data => {
    const { email } = data;

    const payload = {
      ...data,
      lang: i18n.language,
    };
    dispatch(setForm(payload));
    step3({
      ...payload,
      authorization,
    }).then((response: Response) => {
      if (!response.error) {
        if (process.env.NODE_ENV === 'production') {
          dataLayerPush({
            event: 'gtm.formSubmit',
            'gtm.elementClasses': document.querySelector('form').classList,
            'gtm.elementUrl': window.location.href,
            'gtm.email': email,
            label: 'Cadastro Completo',
          });
        }
        const { data } = response;
        dispatch(setConfirmation({ ...data }));
        dispatch(setStep('confirmation'));
      } else {
        let step = 3;
        const fieldsError = Object.keys(response?.error?.data?.errors[0]?.data);
        fieldsError.forEach(item => {
          const field = fields[item];
          if (field) {
            if (field.step < step) {
              step = fields[item].step;
            }
            dispatch(setErrors(field.fieldName));
          }
        });

        if (step !== 3) {
          dispatch(setStep(`step${step}`));
        }
      }
    });
  };

  useEffect(() => {
    if (state.state) {
      updateStates({
        id: state.state,
        country: state.country,
      }).then((response: Response) => {
        resetField('state');
        setValue('state', response.data[0].value);
      });
    }

    if (state.city) {
      updateCities({
        id: state.city,
        state: state.state,
        country: state.country,
      }).then((response: Response) => {
        resetField('city');
        setValue('city', response.data[0].value);
      });
    }

    Object.entries(errorsState)
      .filter(([, { step, hasError }]: any) => step === 3 && hasError)
      .forEach(([key]) => {
        setError(
          key,
          {
            message: `error.${key}.apiError`,
            type: 'apiError',
          },
          {
            shouldFocus: true,
          },
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorsState, isValid]);

  const showSearchAddressModal = () => {
    setShowModal(true);
    if (getValues('address') !== '') {
      setValue('search_zip_code', getValues('address'), {
        shouldValidate: true,
        shouldDirty: true,
      });
      updateAddress({ address: getValues('address') });
    }
  };

  const fillUpForm = address => {
    const list_state = enum_states.filter(item => {
      const { label, value } = item;
      return item.value === address.uf ? { label, value } : '';
    });
    const { uf } = address;
    setValue('country', 'BRA', {
      shouldValidate: true,
      shouldDirty: true,
    });

    const { label } = list_state[0];
    const logradouro = address.logradouroDNEC
      ? address.logradouroDNEC
      : address.logradouro;
    const cityId =
      typeof address.localidade === 'string' ? undefined : address.localidade;
    const cityName =
      typeof address.localidade === 'string' ? address.localidade : undefined;

    setValue('address', logradouro, {
      shouldValidate: true,
      shouldDirty: true,
    });
    updateStates({
      state_name: label,
      country: getValues('country'),
    }).then(() => {
      setValue('state', uf, {
        shouldValidate: true,
        shouldDirty: true,
      });
    });
    setValue('neighborhood', address.bairro, {
      shouldValidate: true,
      shouldDirty: true,
    });
    updateCities({
      id: cityId,
      country: 'BRA',
      state: address.uf,
      city_name: cityName,
    }).then(() => {
      const cityNumber =
        cities !== undefined
          ? cities.find(item => item.label === cityName).value
          : 0;
      setValue(
        'city',
        cityId !== undefined ? Number(address.localidade) : Number(cityNumber),
      );
    });
    resetField('search_zip_code');
  };

  const searchAddress = event => {
    const zipcode = event.target.value;
    setValue('zip_code', zipcode, {
      shouldValidate: true,
      shouldDirty: true,
    });

    if (zipcode.replace(/\D/g, '').length === 8) {
      updateZipCode({ zipcode }).then((response: Response) => {
        if (!response.error && !!response.data.length) {
          const {
            data: [address],
          } = response;
          fillUpForm(address);
        }
      });
    }
  };

  const handleCancel = () => {
    resetField('search_with_state');
    resetField('search_with_city');
    resetField('search_zip_code');
    setShowModal(false);
  };

  const columns = [
    {
      key: 'address',
      title: t('table.address'),
      dataIndex: 'logradouro',
    },
    {
      key: 'zipcode',
      title: t('table.zipcode'),
      dataIndex: 'cep',
    },
    {
      key: 'link',
      title: '',
      render: (_, record) => {
        return (
          <Tooltip title={`Clique aqui para o cep ${record.cep}`}>
            <S.ShowZipCode
              type="link"
              onClick={event => {
                event.preventDefault();
                setValue('zip_code', record.cep, {
                  shouldValidate: true,
                  shouldDirty: true,
                });
                handleCancel();
                fillUpForm(record);
                trigger('zip_code', { shouldFocus: true });
              }}
            >
              Selecionar
            </S.ShowZipCode>
          </Tooltip>
        );
      },
    },
  ];

  const foreign = ![undefined, 'BRA'].includes(watch('country'));

  return (
    <S.Wrapper>
      <S.StepContainer>
        <Steps
          current={2}
          items={items}
          onChange={index => {
            if (index < 2) {
              const data = getValues();
              dispatch(setForm(data));
              dispatch(setStep(`step${index + 1}`));
            }
          }}
        />
      </S.StepContainer>
      <S.Title>
        <Text textType="title" level={1} data-test="title-step">
          {t('step3.title')}
        </Text>
      </S.Title>
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Grid>
          <Grid type="row">
            <Grid type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step3.form.country')}>
                <Field
                  name="country"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: countries,
                    filterOption: (inputValue: string, option: any) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1,
                    showSearch: true,
                    onSelect: () => {
                      resetField('state');
                      resetField('city');
                      resetStates();
                      resetCities();
                    },
                    placeholder: loadingCountries
                      ? t('commons.loading')
                      : t('commons.select'),
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: !foreign ? 11 : 24 }}>
              <Form type="item" label={t('step3.form.zip_code')}>
                <Field
                  name="zip_code"
                  control={control}
                  Component={Input}
                  compProps={{
                    ...(!foreign && {
                      type: showModal ? '' : 'mask',
                      mask: '00000-000',
                    }),
                  }}
                  onChangeCallback={!foreign && searchAddress}
                  errors={errors}
                />
              </Form>
            </Grid>
            {!foreign && (
              <S.Gridlink
                type="col"
                xs={{ span: 11, offset: 2 }}
                xl={{ span: 11 }}
                id="zip_code_link"
              >
                <Button onClick={showSearchAddressModal} type="link">
                  {t('step3.search_zip_code')}
                </Button>
              </S.Gridlink>
            )}
            <Grid type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step3.form.address')}>
                <Field
                  name="address"
                  control={control}
                  Component={Input}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: 8 }}>
              <Form type="item" label={t('step3.form.number')}>
                <Field
                  name="number"
                  control={control}
                  Component={Input}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: 15, offset: 1 }}>
              <Form
                type="item"
                label={t('step3.form.complement')}
                requiredMark="optional"
              >
                <Field
                  name="complement"
                  control={control}
                  Component={Input}
                  errors={errors}
                  compProps={{
                    maxLength: 64,
                    showCount: true,
                  }}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step3.form.neighborhood')}>
                <Field
                  name="neighborhood"
                  control={control}
                  Component={Input}
                  errors={errors}
                  compProps={{
                    maxLength: 64,
                    showCount: true,
                  }}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: 11 }} xl={{ span: 12 }}>
              <Form type="item" label={t('step3.form.state')}>
                <Field
                  Component={Select}
                  name="state"
                  control={control}
                  compProps={{
                    defaultActiveFirstOption: false,
                    disabled: watch('country') === undefined,
                    fetching: loadingStates,
                    filterOption: false,
                    options: states,
                    showSearch: true,
                    onSelect: () => {
                      resetField('city');
                      resetCities();
                    },
                    onKeyUp: evt => {
                      const state_name = evt.target.value;
                      if (state_name.length > 2) {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          updateStates({
                            state_name,
                            country: getValues('country'),
                          });
                        }, 500);
                      }
                    },
                    onKeyPress: () => {
                      clearTimeout(timer);
                    },
                    placeholder: loadingStates
                      ? t('commons.loading')
                      : t('commons.select'),
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid
              type="col"
              xs={{ span: 11, offset: 2 }}
              xl={{ span: 11, offset: 1 }}
            >
              <Form type="item" label={t('step3.form.city')}>
                <Field
                  name="city"
                  control={control}
                  Component={Select}
                  compProps={{
                    defaultActiveFirstOption: false,
                    disabled: watch('state') === undefined,
                    filterOption: false,
                    fetching: loadingCities,
                    options: cities,
                    showSearch: true,
                    onKeyUp: evt => {
                      const city_name = evt.target.value;
                      if (city_name.length > 2) {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          updateCities({
                            city_name,
                            country: getValues('country'),
                            state: getValues('state'),
                          });
                        }, 500);
                      }
                    },
                    onKeyPress: () => {
                      clearTimeout(timer);
                    },
                    placeholder: loadingCities
                      ? t('commons.loading')
                      : t('commons.select'),
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
          </Grid>
        </Grid>
        <Button
          block
          loading={isLoading}
          type="primary"
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          data-test="submit_step3_button"
        >
          {t('step3.submit')}
        </Button>
        <Modal visible={showModal} footer={null} onCancel={handleCancel}>
          <Form
            layout="vertical"
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Grid type="row">
              <Grid
                type="col"
                xs={{ span: 24, offset: 0 }}
                md={{ span: 5, offset: 0 }}
              >
                <Form type="item" label={t('step3.form.search_with_state')}>
                  <Field
                    Component={Select}
                    name="search_with_state"
                    control={control}
                    compProps={{
                      defaultActiveFirstOption: false,
                      fetching: loadingStates,
                      filterOption: true,
                      options: enum_states,
                      showSearch: true,
                      onSelect: () => {
                        resetField('search_with_city');
                        resetCities();
                      },
                    }}
                    errors={errors}
                  />
                </Form>
              </Grid>
              <Grid
                type="col"
                xs={{ span: 24, offset: 0 }}
                md={{ span: 7, offset: 1 }}
              >
                <Form type="item" label={t('step3.form.search_with_city')}>
                  <Field
                    name="search_with_city"
                    control={control}
                    Component={Select}
                    compProps={{
                      defaultActiveFirstOption: false,
                      disabled: watch('search_with_state') === undefined,
                      filterOption: false,
                      fetching: loadingCities,
                      options: cities,
                      showSearch: true,
                      onSearch: (city_name: string | any[]) =>
                        city_name.length > 1 &&
                        updateCities({
                          city_name,
                          country: 'BRA',
                          state: getValues('search_with_state'),
                        }),
                    }}
                    errors={errors}
                  />
                </Form>
              </Grid>
              <Grid
                type="col"
                xs={{ span: 24, offset: 0 }}
                md={{ span: 10, offset: 1 }}
              >
                <Form type="item" label={t('step3.form.search_zip_code')}>
                  <Field
                    name="search_zip_code"
                    control={control}
                    Component={Input}
                    compProps={{
                      type: 'search',
                      disabled: watch('search_with_city') === undefined,
                      onSearch: (address: string) =>
                        updateAddress({
                          address,
                          state: getValues('search_with_state'),
                          city: cities.map(item => {
                            if (item.value === getValues('search_with_city')) {
                              return item.label.replace(',', '');
                            }
                            return '';
                          }),
                        }),
                    }}
                    errors={errors}
                  />
                </Form>
              </Grid>
            </Grid>
            <Table
              columns={columns}
              dataSource={adressess}
              footer={false}
              loading={loadingAdresses}
              showHeader
              pagination={{ position: ['bottomCenter'] }}
              size="small"
              rowKey={record => {
                return `row-${record}`;
              }}
              data-test="table-address"
            />
          </Form>
        </Modal>
        {isError && <ErrorBoundary errors={step3Error} />}
      </Form>
    </S.Wrapper>
  );
};

export default Step3;
