import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .text-mb {
    margin-bottom: 16px;
  }
`;
