import { Grid, Result as ResultCore, Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 80%;
      h1 {
        ${mixins.animation('fade-in', '0.2s')};
        color: ${theme.gray9};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeH1Mobile};
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 0;
        padding-bottom: 16px;
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Result = styled(ResultCore)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      margin: 40px 0;
      padding: 24px;
      border-radius: ${theme.borderRadius};
      .ant-result-icon {
        margin-bottom: 0;
        padding-bottom: 16px;
        svg {
          ${mixins.animation('bounce-top', '1s')};
          &.svg-inline--fa {
            height: 64px;
          }
        }
      }
    `;
  }}
`;

export const TextGuidLine = styled(Text)`
  ${() => {
    return css`
      font-size: 12px;
      font-weight: 600;
      &.ant-typography {
        margin-bottom: 0;
      }
    `;
  }}
`;

export const GridPassword = styled(Grid)`
  ${() => {
    return css`
      &.ant-col {
        form {
          & > div.ant-form-item {
            &:first-child {
              margin-bottom: 0;
              .ant-form-item-control-input-content {
                .ant-typography {
                  display: none;
                }
              }
            }
          }
          & > .ant-typography {
            margin-bottom: 0;
          }
          & > div {
            ul {
              margin-bottom: 56px;
            }
          }
          .ant-btn {
            margin-top: 40px;
          }
        }
      }
    `;
  }}
`;
