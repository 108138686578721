import { Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.lightYellow};
      height: 100%;
      width: 100%;
      ${mixins.animation('fade-in', '0.1s')};
      background-image: url(/assets/images/internMaleImage.png);
      background-size: 80%;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: 340px;

      @media screen and (min-width: 1280px) {
        background-position-y: 300px;
      }
    `;
  }}
`;

export const LogoContainer = styled.div`
  ${() => {
    return css`
      ${mixins.animation('fade-in', '0.6s')};
      margin-top: 64px;
      margin-left: 48px;
      svg,
      img {
        width: 212px;
        height: auto;
      }
      @media screen and (min-width: 1280px) {
        margin-left: 64px;
      }
      @media screen and (min-width: 1360px) {
        margin-left: 68px;
      }
      @media screen and (min-width: 1880px) {
        margin-left: 90px;
      }
    `;
  }}
`;

export const Info = styled.div`
  ${() => {
    return css`
      margin: 22px 48px 0 48px;

      @media only screen and (min-width: 1024px) {
        margin: 22px 48px 0 48px;
      }
      @media only screen and (min-width: 1280px) {
        margin: 42px 64px 0 64px;
      }
      @media only screen and (min-width: 1360px) {
        margin: 42px 68px 0 68px;
      }
      @media only screen and (min-width: 1880px) {
        margin: 42px 90px 0 90px;
      }
    `;
  }}
`;

export const Title = styled(Text)`
  ${({ theme }) => {
    return css`
      flex: none;
      order: 0;
      align-self: stretch;
      flex-grow: 0;
      &.ant-typography {
        margin: 0 !important;
        color: #000;
        font-family: 'Assistant';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 122%;
        span {
          color: ${theme.primaryColor};
        }
      }
    `;
  }}
`;
