import { Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 300px;
      ${mixins.animation('fade-in', '0.2s')};
      h1 {
        &.ant-typography {
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          width: 159px;
        }
      }
      .ant-form-item-label {
        padding-bottom: 0;
      }
      .ant-btn {
        &.ant-btn-primary {
          margin-top: 0px;
        }
      }

      .ant-input-affix-wrapper {
        & > input.ant-input {
          margin: 0;
        }
        .ant-input-show-count-suffix {
          position: absolute;
          right: 0;
          top: 100%;
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: 12px;
        }
      }

      label {
        svg {
          fill: ${theme.primaryColor};
          width: 14px;
          height: 14px;
        }
      }

      @media only screen and (max-width: 320px) {
        .ant-input-affix-wrapper-status-error:has(> #record_student)
          ~ .ant-typography {
          max-width: 180px;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
`;

export const Grid = styled.div`
  padding-bottom: 16px;
`;

export const StepContainer = styled.div`
  margin-bottom: 40px;
`;

export const Info = styled(Text)`
  ${() => {
    return css`
      font-size: 16px;
    `;
  }}
`;
