/**
 * Converts a string from base 64 to binary.
 */
function base64ToBinary(str: string) {
  return typeof atob !== 'undefined'
    ? atob(str)
    : Buffer.from(str, 'base64').toString('binary');
}

/**
 * Converts a string from binary to base 64.
 */
function binaryToBase64(str: string) {
  return typeof btoa !== 'undefined'
    ? btoa(str)
    : Buffer.from(str, 'binary').toString('base64');
}

/**
 * Capitalizes a string.
 * @example
 * capitalize('foo bar') // returns: 'Foo bar'
 * capitalize('foo bar', false) // returns: 'Foo Bar'
 * capitalize('test of faith', false) // returns: 'Test of Faith'
 * capitalize('test of faith', false, 2) // returns: 'Test Of Faith'
 */
function capitalize(str: string, onlyFirst = true, minChars = 3) {
  if (onlyFirst) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const words = str
    .split(' ')
    .map(w => w.trim())
    .filter(w => w);

  return words
    .map(word =>
      words.length === 1 || minChars <= 0 || word.length >= minChars
        ? word.charAt(0).toUpperCase() + word.slice(1)
        : word,
    )
    .join(' ');
}

/**
 * Pluralize the string if the count is greater than one.
 * @example
 * pluralize('test', 0) // returns: 'test'
 * pluralize('test', 1) // returns: 'test'
 * pluralize('test', 2) // returns: 'tests'
 * pluralize('test', 0, true) // returns: '0 test'
 * pluralize('test', 1, true) // returns: '1 test'
 * pluralize('test', 2, true) // returns: '2 tests'
 * pluralize('test', 0, false, '') // returns: ''
 * pluralize('test', 0, false, 'something') // returns: 'something'
 * pluralize('test', 0, true, 'something') // returns: '0 something'
 */
function pluralize(
  str: string,
  count: number,
  includeCount = false,
  whenZero?: string,
) {
  const strCount = includeCount ? `${count} ` : '';
  let plural = `${str}s`;

  if (/^.+(r|s|z)$/i.test(str)) {
    plural = `${str}es`;
  } else if (/^.+(m)$/i.test(str)) {
    plural = `${str.slice(0, -1)}ns`;
  }

  if (count === 0 && whenZero !== undefined) {
    return `${strCount}${whenZero}`;
  }

  return `${strCount}${Math.abs(count) > 1 ? plural : str}`;
}

/**
 * The accent regex list.
 */
const accentRegexList = [
  /(?<A>[ÁÀÂÃÄ])/g,
  /(?<a>[áàâãä])/g,
  /(?<E>[ÉÈÊẼË])/g,
  /(?<e>[éèêẽë])/g,
  /(?<I>[ÍÌÎĨÏ])/g,
  /(?<i>[íìîĩï])/g,
  /(?<O>[ÓÒÔÕÖ])/g,
  /(?<o>[óòôõö])/g,
  /(?<U>[ÚÙÛŨÜ])/g,
  /(?<u>[úùûũü])/g,
];

/**
 * The accent regex group keys.
 */
const accentRegexKeys = 'AaEeIiOoUu'.split('');

/**
 * Removes the accent from a string.
 * @example
 * removeAccent('aéreo') // returns: 'aereo'
 */
function removeAccent(str: string) {
  return accentRegexList.reduce((str, regex) => {
    return str.replace(regex, (match, p1, offset, str, group) => {
      return accentRegexKeys.find(key => group[key]) || match;
    });
  }, str);
}

/**
 * Pad zero to number.
 * @example
 * zeroPad(3) // returns: '03'
 */
 function zeroPad(number: number) {
  return String(number).padStart(2, '0');
}

export { base64ToBinary, binaryToBase64, capitalize, pluralize, removeAccent, zeroPad };
