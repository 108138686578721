import { useSelector } from 'react-redux';
import Confirmation from '@/components/confirmation';
import Email from '@/components/email';
import FormMfa from '@/components/formMfa';
import Header from '@/components/header';
import Login from '@/components/login';
import ReactivateAccount from '@/components/reactivateAccount';
import Reactivated from '@/components/reactivated';
import RecoveryEmail from '@/components/recoveryEmail';
import RecoveryPassword from '@/components/recoveryPassword';
import Register from '@/components/register';
import RemoveMfa from '@/components/removeMfa';
import Resend from '@/components/resend';
import ResetPassword from '@/components/resetPassword';
import Step1 from '@/components/step1';
import Step2 from '@/components/step2';
import Step3 from '@/components/step3';
import Theming from '@/components/theming';
import * as S from './styles';

const Template = () => {
  const { step } = useSelector(({ config }) => config);

  const renderForm = step => {
    switch (step) {
      case 'theming':
        return <Theming />;
      case 'register':
        return <Register />;
      case 'step1':
        return <Step1 />;
      case 'step2':
        return <Step2 />;
      case 'step3':
        return <Step3 />;
      case 'confirmation':
        return <Confirmation />;
      case 'reactivated':
        return <Reactivated />;
      case 'resend':
        return <Resend />;
      case 'email':
        return <Email />;
      case 'login':
        return <Login />;
      case 'reactivate_account':
        return <ReactivateAccount />;
      case 'recovery_email':
        return <RecoveryEmail />;
      case 'recovery_password':
        return <RecoveryPassword />;
      case 'reset_password':
        return <ResetPassword />;
      case 'form_mfa':
        return <FormMfa />;
      case 'remove_mfa':
        return <RemoveMfa />;
      default:
        break;
    }
    return <div>Not Found</div>;
  };
  return (
    <S.Grid type="col" xs={24} lg={12}>
      <Header />
      {renderForm(step)}
    </S.Grid>
  );
};

export default Template;
