/* eslint no-console: ["error", { allow: ["error"] }] */
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Notification } from '@/core';
import { flatten } from '@/lib/utils/array';

export interface ErrorBoundaryProps {
  errors?: any;
  message?: string;
  type?: string;
}

const ErrorBoundary: FC<ErrorBoundaryProps> = ({
  errors,
  message,
  type = 'error',
}) => {
  let genericError;
  let title;
  const { t } = useTranslation();
  let jsonErrors;
  let description;

  if (message) {
    description = message;

    console.error(message);
  } else {
    try {
      const {
        data: {
          errors: [{ data: obj }],
        },
      } = errors;
      if (typeof obj === 'string') {
        throw new Error('badly_formatted');
      }
      jsonErrors = flatten(obj);
    } catch (error) {
      title = 'ops';
      genericError = true;
      jsonErrors = { 'response.badly_formatted': true };
    }

    description = Object.keys(jsonErrors).map(item => (
      <div>{t(`api.error.${item}`)}</div>
    ));

    console.error(errors);
  }

  return (
    <Notification
      style={{
        width: 460,
      }}
      description={
        <>
          {description}
          {genericError && (
            <>
              {t('api.error.response.badly_formatted2')}
              <br />
              <br />
              {t('api.error.response.phone')}
              {` `}
              <a href="tel:+551159069030">+55 (11) 5906-9030</a>
              <br />
              {t('api.error.response.email')}
              {` `}
              <a href="mailto:ola@ciadeestagios.com.br">
                ola@ciadeestagios.com.br
              </a>
              <br />
              {t('api.error.response.site')}
              {` `}
              <a
                href="https://ajuda.ciadeestagios.com.br/"
                target="_blank"
                rel="noreferrer"
              >
                https://ajuda.ciadeestagios.com.br/
              </a>
            </>
          )}
        </>
      }
      duration={3}
      message={t(`api.error.title.${title || type}`)}
      type={type}
    />
  );
};

export default ErrorBoundary;
