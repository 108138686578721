/* eslint no-console: ["error", { allow: ["log"] }] */
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text, Button } from '@/core';
import { Email as IconEmail } from '@/lib/assets/svg';
import { useResendMutation } from '@/lib/service/register';
import Loading from '../loading';
import * as S from './styles';

const SubtTitleText = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text className="ant-result-subtitle-text">{t('email.subtitle')}</Text>
      <Text className="ant-result-subtitle-email-title">
        <b>{t('email.title_email')}</b>
      </Text>
    </>
  );
};

const Email = () => {
  const { t, i18n } = useTranslation();
  const email = useSelector(({ register: { email } }) => email);
  const [resend, { isLoading }] = useResendMutation();

  const resendEmail = () => {
    resend({
      email,
      lang: i18n.language,
    }).then(response => {
      console.log(response);
    });
  };

  return (
    <S.Wrapper loading={isLoading}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Text
            textType="title"
            level={1}
            style={{ marginTop: 40 }}
            data-test="email_title"
          >
            {t('email.title')}
          </Text>
          <S.Result icon={<IconEmail />} subTitle={<SubtTitleText />} />
          <S.Info>
            <Text>
              {t('email.info')}&nbsp;
              <Button type="link" onClick={resendEmail}>
                {t('email.resend')}
              </Button>
            </Text>
          </S.Info>
        </>
      )}
      {/* <S.Link>{t('email.login')}</S.Link> */}
    </S.Wrapper>
  );
};

export default Email;
