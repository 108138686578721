import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Response from '@/lib/model/api/response';

export const api = createApi({
  reducerPath: 'api/reCAPTCHA',
  baseQuery: fetchBaseQuery({ baseUrl: '/api/reCAPTCHA' }),
  endpoints: builder => ({
    reCAPTCHA: builder.mutation<Response, any>({
      query: (payload) => ({
        method: 'POST',
        url: '',
        body: {
          ...payload,
        },
      }),
      transformResponse: ({ data }) => data,
    }),
  }),
});

export const {
  useReCAPTCHAMutation,
} = api;
