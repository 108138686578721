import { Grid } from '@/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .text-mb {
    margin-bottom: 16px;
  }
`;

export const ColorContainer = styled(Grid)`
  margin-bottom: 32px;
`;

export const ColorCard = styled.div`
  ${({ theme, bgColor }) => {
    return css`
      background-color: ${theme[bgColor]};
      border-radius: 8px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 120px;
      width: 204px;
      padding: 42px;

      .ant-typography {
        margin-bottom: 0;
        color: ${bgColor === 'primaryColor'
          ? theme.whiteBackground
          : theme.black};
      }
    `;
  }}
`;
