import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const List = styled.ul`
  ${() => {
    return css`
      padding: 0;
      list-style: none;
    `;
  }}
`;

export const ListItem = styled.li`
  ${({ theme, state = 'default' }) => {
    let textColor = theme.gray10;
    if (state === 'error') {
      textColor = theme.errorColor;
    }
    if (state === 'valid') {
      textColor = theme.primaryColor;
    }
    return css`
      color: ${textColor};
      ${state === 'valid' && 'text-decoration-line: line-through'};
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: 400;
      div {
        display: grid;
        align-items: end;
        height: 100%;
        svg {
          width: 16px;
          height: 16px;
          margin-right: 8px;
          ${state === 'valid'
            ? `path { fill: ${theme.primaryColor}; ${mixins.animation(
                'fade-in',
                '0.2s',
              )};}`
            : `path { fill: ${theme.errorColor}; ${mixins.animation(
                'fade-in',
                '0.2s',
              )};}`};
          ${state === 'default' &&
          `transform: rotate(0.5turn);path { fill: ${theme.gray10};}`}
        }
      }
    `;
  }}
`;
