import { Button, Form } from '@/core';
import * as S from './styles';

const Step1 = () => (
  <S.Wrapper>
    <S.Title>Exemplo de tema</S.Title>
    <Form
      layout="vertical"
      initialValues={{
        layout: 'vertical',
      }}
    >
      <S.PrimaryButton type="primary" block size="large">
        Primary
      </S.PrimaryButton>
      <S.ErrorButton type="error" block size="large">
        Error
      </S.ErrorButton>
      <S.InfoButton type="info" block size="large">
        Info
      </S.InfoButton>
      <S.WarningButton type="warning" block size="large">
        Warning
      </S.WarningButton>
      <S.ProcessingButton type="processing" block size="large">
        Processing
      </S.ProcessingButton>
      <S.SuccessButton type="sucess" block size="large">
        Success
      </S.SuccessButton>
      <Button type="primary" block size="large">
        Custom sem tema
      </Button>
    </Form>
  </S.Wrapper>
);

export default Step1;
