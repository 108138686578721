import { Button } from '@/core';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  margin: 40px auto;
  width: 80%;
  h1 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 900;
    margin-bottom: 0;
  }
  @media only screen and (min-width: 768px) {
    width: 480px;
  }
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 46px;
`;

export const ErrorButton = styled(Button)`
  background-color: var(--theme-error-color);
`;

export const InfoButton = styled(Button)`
  background-color: var(--theme-info-color);
`;

export const PrimaryButton = styled(Button)`
  background-color: var(--theme-primary-color);
`;

export const ProcessingButton = styled(Button)`
  background-color: var(--theme-processing-color);
`;

export const SuccessButton = styled(Button)`
  background-color: var(--theme-success-color);
`;

export const WarningButton = styled(Button)`
  background-color: var(--theme-warning-color);
`;
