import { Button, Checkbox, Divider, Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme, recaptcha }) => {
    return css`
      margin: 40px auto;
      width: 300px;

      h1 {
        &.ant-typography {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          padding-bottom: 40px;
        }
      }
      .select-ddi {
        border: 0;
        margin: 0;
        div.ant-select-selector {
          border: 0;
          padding: 0;
          height: 23px;
          width: 56px !important;
          span.ant-select-selection-item {
            font-size: 22px;
            border-radius: 8px;
            width: 60px;
          }
        }
        div.ant-select-selector ~ .ant-select-arrow {
          right: 2px;
        }
        .ant-btn-link {
          white-space: break-spaces;
          text-align: left;
          height: auto;
          width: 100%;
        }
        .ant-btn {
          &.ant-btn-primary {
            margin-top: 16px;
          }
        }

        .ant-input-affix-wrapper {
          & > input.ant-input {
            margin: 0;
          }
          .ant-input-show-count-suffix {
            position: absolute;
            right: 0;
            top: 100%;
            color: ${theme.gray9};
            font-family: ${theme.fontFamily};
            font-size: 12px;
          }
        }
      }
      .reCAPTCHA {
        margin: ${recaptcha ? '0' : '40px 0'};
        iframe {
          border: ${recaptcha ? '1px solid red' : '0'};
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
      }
    `;
  }};
`;

export const Subtitle = styled.div`
  ${({ theme }) => {
    return css`
      margin: 32px 0;
      display: inline-flex;
      font-weight: 400;
      line-height: 20px;
      font-family: ${theme.fontFamily};
      width: 100%;
      justify-content: center;
      .ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray9};
      }
      a.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.linkColor};
      }
      .ant-btn-link {
        line-height: 18px;
        font-weight: 600;
      }
      .ant-typography {
        margin-bottom: 0;
      }
      gap: 0 4px;
    `;
  }};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  ${mixins.animation('fade-in', '0.2s')};
`;

export const SocialButton = styled(Button)`
  background: #fff;
  border: 0;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: baseline;
  padding: 12px 8px;
  margin-right: 10px;
  ${mixins.animation('fade-in', '0.2s')};
  &:last-child {
    margin-right: 0;
  }
`;

export const Div = styled(Divider)`
  ${mixins.animation('fade-in', '0.2s')};
  text-transform: uppercase;
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 24px 0;
  }
`;

export const Accept = styled.div`
  ${({ theme }) => {
    return css`
      margin: 24px 0 0 0;
      label {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray8};
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    `;
  }}
`;

export const TextSocialButtons = styled(Text)`
  ${({ theme }) => {
    return css`
      &.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        color: ${theme.gray7};
        font-family: ${theme.fontFamily};
        margin-bottom: 8px;
      }
    `;
  }}
`;

export const ChecboxTermAndPolicts = styled(Checkbox)`
  ${({ theme }) => {
    return css`
      & span {
        font-family: ${theme.fontFamily};
        color: ${theme.gray8};
        ${mixins.animation('fade-in', '0.2s')};
        a {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.linkColor};
          ${theme.borderRedTest}
        }
      }
    `;
  }}
`;

export const WrapperModal = styled.div`
  height: 300px;
  overflow-y: auto;
  padding: 0 12px;
  @media only screen and (min-width: 768px) {
    height: 360px;
  }
  @media only screen and (min-width: 1600px) {
    height: 500px;
  }
`;

export const errorRECAPTCHA = styled.div`
  ${({ theme }) => {
    return css`
      color: ${theme.errorColor};
      padding-bottom: 40px;
    `;
  }};
`;

export const TermsAndPoliceButtons = styled(Button)`
  &.ant-btn-link {
    font-weight: 600;
  }
`;
