import { Grid } from '@/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled(Grid)`
  background-color: transparent;
`;

export const ColoredLogoContainer = styled(Grid)`
  margin-bottom: 32px;
`;

export const ImageCard = styled.div`
  ${({ theme, bgColor }) => {
    return css`
      background-color: ${theme[bgColor]};
      border-radius: 8px;
      border: 1px solid
        ${bgColor === 'whiteBackground' ? theme.black : theme[bgColor]};
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        height: auto;
        width: 100%;
      }
      .white-filter {
        filter: invert(1) brightness(100) contrast(100);
      }
    `;
  }}
`;
