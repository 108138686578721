import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Text } from '@/core';
import { Trophy } from '@/lib/assets/svg';
import { setStep } from '@/lib/store/config';
import * as S from './styles';

const timeout = 5000;

const Reactivated = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [count, setCount] = useState(timeout / 1000);

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);

  setTimeout(() => {
    dispatch(setStep('login'));
  }, timeout);

  return (
    <S.Wrapper>
      <S.Title>
        <Text textType="title" level={1}>
          {t('reactivated.title')}
        </Text>
      </S.Title>
      <S.Result icon={<Trophy />} subTitle={t('reactivated.congrats')} />
      <S.Text>{`${t('confirmation.wait')} ${t('commons.second', { count })} ${t(
        'confirmation.click_below',
      )}`}</S.Text>
      <S.Link onClick={() => dispatch(setStep('login'))}>
        {t('confirmation.login')}
      </S.Link>
    </S.Wrapper>
  );
};

export default Reactivated;
