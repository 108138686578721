import * as S from './styles';

const TooltipContent = ({ title, content, emotion = [] }) => {
  return (
    <S.Wrapper>
      <p>
        <b>{title}</b>
      </p>
      <p>
        {content}
        {emotion.map(item => {
          return item;
        })}
      </p>
    </S.Wrapper>
  );
};

export default TooltipContent;
