export default function dataLayerPush(params: any): void {
  try {
    const pushData = {
      ...params,
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(pushData);
  } catch (e) {
    console.error(e);
  }
}
