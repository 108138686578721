import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ErrorBoundary, Field } from '@/components';
import { Text, Button, Input, Form } from '@/core';
import Response from '@/lib/model/api/response';
import { useLoginMutation } from '@/lib/service/authenticate';
import { setForm } from '@/lib/store/authenticate';
import { setStep } from '@/lib/store/config';
import { setForm as setFormReactive } from '@/lib/store/reactive';
import { authenticate as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const initialState = {
  type: 'error',
  message: '',
};

const Login = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(initialState);
  const [login, { isLoading }] = useLoginMutation();

  const { t } = useTranslation();

  const setMessage = errorState => {
    setErrorMessage({
      ...initialState,
      ...errorState,
    });
    setTimeout(() => {
      setErrorMessage(initialState);
    }, 3000);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = async data => {
    login(data).then((response: Response) => {
      dispatch(
        setForm({
          ...data,
          mfa_alternative: response?.data?.mfa_alternative,
        }),
      );
      if (!response.error) {
        if (response.data.status === 206) {
          dispatch(setStep('form_mfa'));
        } else {
          // eslint-disable-next-line no-restricted-globals
          location.href = response.data.url;
        }
      } else if (response.error.status === 400) {
        setMessage({
          message: t('api.error.user.token'),
        });
      } else if (response.error.status === 401) {
        setMessage({
          message: t('error.authorization'),
        });
      } else if (response.error.status === 404) {
        dispatch(setFormReactive({ identity: data.identity }));
        dispatch(setStep('reactivate_account'));
      } else if (response.error.status === 409) {
        setMessage({
          message: t('error.authorization_conflict'),
          type: 'info',
        });
      } else {
        setMessage({
          message: t('error.authorization'),
        });
      }
    });
  };

  return (
    <S.Wrapper>
      <Text
        textType="title"
        level={1}
        style={{ marginTop: 40 }}
        data-test="login_title"
      >
        {t('login.title')}
      </Text>
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Form type="item" label={t('login.login_email')}>
          <Field
            Component={Input}
            control={control}
            errors={errors}
            name="identity"
          />
        </Form>
        <Form
          type="item"
          label={t('login.login_password')}
          className="login-last-item"
        >
          <Field
            Component={Input}
            control={control}
            compProps={{
              type: 'password',
              ...(errors.password?.type && {
                error: t(`error.password.${errors.password?.type}`),
              }),
            }}
            errors={errors}
            name="password"
            withPlaceholder={false}
          />
        </Form>
        <S.Accept>
          <S.ForgotPasswordButton
            type="link"
            onClick={() => dispatch(setStep('recovery_password'))}
            data-test="link_forget_password"
          >
            {t('login.forget_password')}
          </S.ForgotPasswordButton>
        </S.Accept>
        <Button
          block
          disabled={!!errorMessage.message || !isValid}
          loading={isLoading}
          size="large"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          data-test="submit_login"
        >
          {t('commons.send')}
        </Button>
      </Form>
      <S.Subtitle>
        <Text>{t('login.subtitle')}</Text>
        <Button
          type="link"
          onClick={() => dispatch(setStep('register'))}
          data-test="link_register"
        >
          {t('login.register')}
        </Button>
      </S.Subtitle>
      {!!errorMessage.message && (
        <ErrorBoundary
          message={errorMessage.message}
          type={errorMessage.type}
        />
      )}
    </S.Wrapper>
  );
};

export default Login;
