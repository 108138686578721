import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary, Field } from '@/components';
import { Form, Text, Button, Input, Grid } from '@/core';
import { BrokenLink, Email as IconEmail } from '@/lib/assets/svg';
import Response from '@/lib/model/api/response';
import { useForgotPasswordMutation } from '@/lib/service/authenticate';
import { setForm } from '@/lib/store/resend';
import { recoveryPassword as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const SubTitle = () => {
  const { t } = useTranslation();
  return <Text>{t('recovery.password.response_sent')}</Text>;
};

const RecoveryPassword = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const state = useSelector(({ config: { recoveryPass } }) => ({
    ...recoveryPass,
  }));

  const [forgotPassword, { isLoading, isError, error: forgotError }] =
    useForgotPasswordMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const [sendPassword, setSendPassword] = useState(false);

  const onSubmit = data => {
    dispatch(setForm(data));
    forgotPassword(data).then((response: Response) => {
      if (!response.error) {
        setSendPassword(true);
      }
    });
  };

  const { expired } = state;

  return (
    <S.Wrapper>
      <Text
        textType="title"
        level={1}
        style={{ marginTop: 40 }}
        data-test="recovery_password_title"
      >
        {expired
          ? t('recovery.password.title_expired')
          : t('recovery.password.title')}
      </Text>
      {expired && !sendPassword && (
        <S.Result
          icon={<BrokenLink />}
          subTitle={
            <>
              <Text>{t('recovery.password.information_1_expired')}</Text>
              <Text>{t('recovery.password.information_2_expired')}</Text>
            </>
          }
        />
      )}
      {!sendPassword ? (
        <Grid type="col">
          <Text textType="text" className="text-instructions">
            {!expired && t('recovery.password.information')}
          </Text>
          <Form
            layout="vertical"
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Form type="item" label={t('recovery.password.input')}>
              <Field
                Component={Input}
                compProps={{
                  value: state.value,
                  placeholder: t('placeholder.identity_recovery_password'),
                }}
                control={control}
                errors={errors}
                name="identity"
              />
            </Form>
            <Button
              block
              loading={isLoading}
              size="large"
              type="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={!isValid}
              data-test="recovery_password_submit"
            >
              {t('recovery.password.send_email')}
            </Button>
          </Form>
        </Grid>
      ) : (
        <S.Result icon={<IconEmail />} subTitle={<SubTitle />} />
      )}
      {isError && <ErrorBoundary errors={forgotError} />}
    </S.Wrapper>
  );
};

export default RecoveryPassword;
