import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary, Field, HelperPassword } from '@/components';
import { Form, Button, Text, Input } from '@/core';
import { Trophy } from '@/lib/assets/svg';
import { useResetPasswordMutation } from '@/lib/service/authenticate';
import { setStep } from '@/lib/store/config';
import { formatDuration } from '@/lib/utils/date';
import { resetPassword as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const RecoveryPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reseted, setReseted] = useState(false);

  const state = useSelector(({ config: { resetPass } }) => ({
    ...resetPass,
  }));

  const { timeout, authorization } = state;

  const [resetPassword, { isError, error: resetPasswordError }] =
    useResetPasswordMutation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const [count, setCount] = useState(timeout / 1000);

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);

  const onSubmit = data => {
    resetPassword({
      authorization,
      payload: data,
    }).then((response: any) => {
      if (!response.error) {
        setReseted(true);
        setTimeout(() => {
          dispatch(setStep('login'));
        }, 5000);
      }
    });
  };

  return (
    <S.Wrapper>
      {reseted ? (
        <>
          <Text
            textType="title"
            level={1}
            style={{ marginTop: 40 }}
            data-test="reset_password_success_title"
          >
            {t('reset_password.success')}
          </Text>
          <S.Result icon={<Trophy />} subTitle={t('reset_password.congrats')} />
        </>
      ) : (
        <>
          <Text
            textType="title"
            level={1}
            style={{ marginTop: 40 }}
            data-test="reset_password_title"
          >
            {t('reset_password.title')}
          </Text>
          <S.GridPassword type="col">
            <Text textType="title" level={4}>
              {t('reset_password.subtitle')}
              {formatDuration(count)}
            </Text>
            <Form
              layout="vertical"
              initialValues={{
                layout: 'vertical',
              }}
            >
              <Form type="item" label={t('reset_password.form.field')}>
                <Field
                  name="password"
                  control={control}
                  Component={Input}
                  compProps={{
                    type: 'password',
                    ...(errors.password?.type && {
                      error: t(`error.password.${errors.password?.type}`),
                    }),
                    visibilityToggle: {
                      visible: true,
                    },
                  }}
                  errors={errors}
                  withPlaceholder={false}
                />
              </Form>
              <S.TextGuidLine>{t(`error.password.guidelines`)}</S.TextGuidLine>
              <HelperPassword password={watch('password')} />

              <Form type="item" label={t('reset_password.form.confirmation')}>
                <Field
                  name="password_confirmation"
                  control={control}
                  Component={Input}
                  compProps={{
                    type: 'password',
                    ...(errors.password_confirmation?.type && {
                      error: t(
                        `error.password_confirmation.${errors.password_confirmation?.type}`,
                      ),
                    }),
                    visibilityToggle: {
                      visible: true,
                    },
                  }}
                  errors={errors}
                  withPlaceholder={false}
                />
              </Form>
              <Button
                block
                disabled={count <= 0 || !isValid}
                size="large"
                type="primary"
                onClick={handleSubmit(onSubmit)}
                data-test="submit_reset_password"
              >
                {t('reset_password.action')}
              </Button>
              {isError && <ErrorBoundary errors={resetPasswordError} />}
            </Form>
          </S.GridPassword>
        </>
      )}
    </S.Wrapper>
  );
};

export default RecoveryPassword;
