import { Grid, Modal as ModalAntd } from '@/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const DescriptionWrapper = styled.div`
  ${() => {
    return css`
      margin: 24px 0;
    `;
  }}
`;

export const Modal = styled(ModalAntd)`
  ${({ theme }) => {
    return css`
      width: 572px !important;

      .ant-modal-content .ant-modal-body {
        padding: 40px;
      }

      h1 {
        color: ${theme.gray8};
        font-family: Assistant;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 32px; /* 133.333% */
      }

      .modal-description {
        color: ${theme.gray8};
        font-family: Assistant;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button.ant-btn-primary > span {
        color: var(--Gray-gray-2, #fafafa);
        font-family: Assistant;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    `;
  }}
`;

export const FeedbackContainer = styled(Grid)`
  ${() => {
    return css`
      margin: 24px 0;

      .ant-alert {
        width: 100%;
      }
    `;
  }}
`;
