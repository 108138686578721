import { useTranslation } from 'react-i18next';
import { Grid, Icon, Spin, Text } from '@/core';
import * as S from './styles';

const Loading = () => {
  const { t } = useTranslation();
  return (
    <S.Wrapper>
      <Grid type="col" lg={24}>
        <S.AlignedContainer>
          <Spin indicator={<Icon iconName="LoadingOutlined" />} />
          <Text textType="title" level={2}>
            {t('commons.loading')}
          </Text>
        </S.AlignedContainer>
      </Grid>
    </S.Wrapper>
  );
};

export default Loading;
