/* eslint no-console: ["error", { allow: ["log"] }] */
import { useEffect, useState } from 'react';
import Head from 'next/head';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useBreakpoint from 'use-breakpoint';
import DevTools from '@/components/devTools';
import Info from '@/components/info';
import Loading from '@/components/loading';
import Template from '@/components/template';
import { Grid, Container, Button } from '@/core';
import { Interrogation } from '@/lib/assets/svg';
import { CountryInfo } from '@/lib/enum';
import { useHashMutation } from '@/lib/service/register';
import { setForm as setAuthenticateForm } from '@/lib/store/authenticate';
import { setStep, setAuthorization } from '@/lib/store/config';
import { setForm as setFormRegister } from '@/lib/store/register';
import { setForm as setFormStep1 } from '@/lib/store/step1';
import { setForm as setFormStep2 } from '@/lib/store/step2';
import * as S from '@/lib/styles/pages/style';
import { searchCookie } from '@/lib/utils/cookies';

const BREAKPOINTS = {
  mobile: 0,
  tablet: 768,
  desktop: 1024,
};

const App: any = ({ isLoading }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [stateLoading, setLoading] = useState(true);
  const [updateHash, { isLoading: serviceLoading }] = useHashMutation();
  const { tokenSent } = useSelector(
    ({ authenticate: { tokenSent }, config: { step } }) => ({
      tokenSent,
      step,
    }),
  );

  const loading = stateLoading || serviceLoading || isLoading;

  useEffect(() => {
    if (tokenSent) {
      setTimeout(() => {
        dispatch(
          setAuthenticateForm({
            tokenSent: false,
          }),
        );
      }, 120000);
    }
  }, [tokenSent, dispatch]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const hash = params.get('hash');
    const queryLang = `${params.get('lang') || 'BR'}`.toUpperCase();
    const lang =
      CountryInfo[queryLang === 'EN' ? 'US' : queryLang]?.language || 'pt';

    if (lang) {
      i18n.changeLanguage(lang);
    }

    if (hash) {
      updateHash({ hash }).then((response: any) => {
        if (!response.error) {
          const {
            data: { step, jwt, ...state },
          } = response;
          dispatch(setAuthorization(jwt));
          dispatch(
            setFormRegister({
              email: state.email,
              mobile_phone: state.mobile_phone,
              mobile_phone_ddd: state.mobile_phone_ddd,
              mobile_phone_ddi: state.mobile_phone_ddi,
              doesnt_have_mobile_phone: state.doesnt_have_mobile_phone,
            }),
          );
          dispatch(setFormStep1(state.personal_data));
          dispatch(setFormStep2(state.teaching_data));
          dispatch(setStep(`step${step}`));
        }
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop');

  return (
    <>
      <Head>
        <title>Companhia de Estágios</title>
      </Head>
      <Container>
        <S.Wrapper>
          <Grid type="row" span={24}>
            {breakpoint === 'desktop' && (
              <Grid type="col" span={12}>
                <Info />
              </Grid>
            )}
            {loading ? <Loading /> : <Template />}
            <Button
              type="primary"
              size="large"
              onClick={() => {
                window.open(
                  'https://www.ciadeestagios.com.br/central-de-ajuda/',
                );
              }}
              className="btn_help_button"
            >
              <Interrogation />
              Ajuda
            </Button>
          </Grid>
        </S.Wrapper>
        {process.env.NODE_ENV !== 'production' && <DevTools />}
      </Container>
    </>
  );
};

App.getInitialProps = request => {
  const { req, res } = request;
  if (searchCookie(req, 'cia_carreira_sessionId')) {
    res.writeHead(307, {
      Location: `${process.env.APP_URL}${
        request?.query?.redirect ? request.query.redirect : ''
      }`,
    });
    res.end();
  }
  return {};
};

export default App;
