import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as CustomLink, Grid } from '@/core';
import { Logo } from '@/lib/assets/svg';
import { setStep } from '@/lib/store/config';
import * as S from './styles';

const Info = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { step } = useSelector(({ config }) => config);

  const renderInfoSteps = () => (
    <Grid type="col">
      <S.Title textType="title" level={1}>
        {t('infoSteps.data1')}
        <span>{t('infoSteps.data2')}</span>
        {t('infoSteps.data3')}
        <span>{t('infoSteps.data4')}</span>
      </S.Title>
    </Grid>
  );

  const renderInfo = step => {
    switch (step) {
      case 'register':
        return (
          <Grid type="col">
            <S.Title textType="title" level={1}>
              {t('infoRegister.data1')}
              {t('infoRegister.data2')}
            </S.Title>
            <S.Title textType="title" level={1}>
              <span>{t('infoRegister.data3')}</span>
            </S.Title>
          </Grid>
        );
      case 'step1':
        return renderInfoSteps();
      case 'step2':
        return renderInfoSteps();
      case 'step3':
        return renderInfoSteps();
      default:
        return (
          <Grid type="col">
            <S.Title textType="title" level={1}>
              {t('info.data1')}
            </S.Title>
          </Grid>
        );
    }
  };

  return (
    <S.Wrapper xs={24}>
      <Grid type="row" span={24}>
        <S.LogoContainer>
          <CustomLink onClick={() => dispatch(setStep('register'))}>
            <Logo />
          </CustomLink>
        </S.LogoContainer>
      </Grid>
      <Grid type="row" span={24}>
        <S.Info>{renderInfo(step)}</S.Info>
      </Grid>
    </S.Wrapper>
  );
};

export default Info;
