import { parse, isDate } from 'date-fns';
import * as yup from 'yup';
import YupPassword from 'yup-password';
import { cpfIsValid } from '@/lib/utils/validation';
import { calculateAge } from '@/lib/utils/date';

YupPassword(yup);

yup.setLocale({
  mixed: {
    oneOf: field => {
      const { path } = field;
      if (path === 'accept') {
        return 'error.accept';
      }
      return 'error';
    },
    required: 'error.required',
    notType: field => {
      const { path } = field;
      return `error.notType.${path}`;
    },
  },
  string: {
    email: 'error.email',
    min: ({ min }) => ({ error: 'error.min', min }),
    max: ({ max }) => ({ error: 'error.max', max }),
  },
  date: {
    max: 'error.date.max',
  },
});

const config = yup
  .object({
    delay: yup.number().default(3000).nullable(),
    method: yup
      .mixed()
      .oneOf(['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS'])
      .required(),
    mockAll: yup.bool().default(false).required(),
    path: yup.string().required(),
    response: yup.string().nullable(),
    responseHeader: yup.object().nullable(),
    statusCode: yup.number().nullable(),
    useMocked: yup.bool().default(false).required(),
    useResponseDefault: yup.bool().default(false).required(),
  })
  .required();

const register = yup
  .object({
    email: yup.string().when('social', {
      is: value => !value,
      then: yup.string().email().min(6).required(),
    }),
    mobile_phone: yup
      .object({
        mobile_phone_ddi: yup.number().required(),
        mobile_phone_ddd: yup.number().nullable(),
        mobile_phone: yup.number().required(),
      })
      .transform((_, other) => {
        if (other.match(/\((.*?)\)/) === null) {
          return {
            mobile_phone_ddi: 0,
            mobile_phone_ddd: 0,
            mobile_phone: 0,
          };
        }
        let mobile_phone_ddi =
          (other.match(/\((.*?)\)/)[1] !== null &&
            other.match(/\((.*?)\)/)[1]) ||
          0;
        let mobile_phone_ddd;
        let mobile_phone;
        if (mobile_phone_ddi === '55') {
          mobile_phone_ddd =
            (other.match(/\ (.*?)\ /) !== null &&
              other.match(/\ (.*?)\ /)[1]) ||
            0;
          mobile_phone =
            other.split(' ')[2] !== undefined
              ? other.split(' ')[2].replace(/\D/g, '')
              : 0;
        } else {
          mobile_phone =
            other.split(' ')[1] !== undefined
              ? other.split(' ')[1].replace(/\D/g, '')
              : 0;
        }
        return {
          mobile_phone_ddi,
          mobile_phone_ddd,
          mobile_phone,
        };
      })
      .test(
        'error.notType.mobile_phone',
        'error.notType.mobile_phone',
        ({ mobile_phone_ddi, mobile_phone_ddd, mobile_phone }) => {
          if (mobile_phone_ddi === 55) {
            return (
              mobile_phone_ddd > 0 &&
              mobile_phone_ddd < 999 &&
              `${mobile_phone}`.length === 9
            );
          }
          return (
            `${mobile_phone}`.length >= 4 && `${mobile_phone}`.length <= 12
          );
        },
      ),
    accept: yup.bool().default(false).oneOf([true]),
    social: yup.bool().default(false),
  })
  .required();

const reactive = yup
  .object({
    identity: yup
      .string()
      .required()
      .test('identity', 'error.email', function (value) {
        const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        const isValidEmail = emailRegex.test(value);
        const emailError = this.createError({
          message: 'error.email',
          path: 'identity',
        });
        if (value && value.includes('@')) {
          if (!isValidEmail) {
            return emailError;
          }
          return true;
        }
      }),
  })
  .required();

const authenticate = yup.object({
  identity: yup
    .string()
    .required()
    .test('identity', function (value) {
      const emailRegex = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
      const isValidEmail = emailRegex.test(value);
      const emailError = this.createError({
        message: 'error.email',
        path: 'identity',
      });
      const lengthError = this.createError({
        message: 'error.min_identity',
        path: 'identity',
      });
      if (value === undefined) {
        return lengthError;
      } else if (value && value.includes('@')) {
        if (!isValidEmail) {
          return emailError;
        }
        return true;
      } else {
        if (value.length <= 6) {
          return lengthError;
        }
        return true;
      }
    }),
  password: yup.string().required(),
});

const formMfa = yup
  .object({
    mfa: yup.string().required(),
  })
  .required();

const removeMfa = yup
  .object({
    document: yup
      .string()
      .transform(value => value.replace(/[^a-zA-Z0-9]/g, ''))
      .required(),
    email: yup.string().email().required(),
    zipcode: yup
      .string()
      .transform(value => value.replace(/[^a-zA-Z0-9]/g, ''))
      .required(),
  })
  .required();

const resend = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();

const recoveryEmail = yup
  .object({
    document: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().required(),
    confirm_email: yup.string().email().required(),
  })
  .required();

const resetPassword = yup
  .object({
    password: yup
      .string()
      .min(8)
      .minLowercase(1)
      .minUppercase(1)
      .minNumbers(1)
      .minSymbols(1)
      .required(),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref('password'), null])
      .required(),
  })
  .required();

const recoveryPassword = yup
  .object({
    identity: yup.string().email().required(),
  })
  .required();

const step1 = yup
  .object({
    full_name: yup
      .string()
      .transform(value => {
        return value.trim();
      })
      .test(
        'error.notType.full_name',
        'error.notType.full_name',
        full_name =>
          full_name.split(' ').filter(name => name.trim()).length > 1,
      )
      .min(3)
      .required(),
    password: yup
      .string()
      .min(8)
      .minLowercase(1)
      .minUppercase(1)
      .minNumbers(1)
      .minSymbols(1)
      .required(),
    nationality: yup.string().required(),
    birth_date: yup
      .date()
      .transform((value, originalValue) => {
        const parsedDate = isDate(originalValue)
          ? originalValue
          : parse(originalValue, 'dd/MM/yyyy', new Date());

        return parsedDate;
      })
      .test('min', 'error.date.min', birthdate => {
        const age = calculateAge(new Date(), new Date(birthdate));
        return age > 13;
      })
      .test('max', 'error.date.max', birthdate => {
        const age = calculateAge(new Date(), new Date(birthdate));
        return age <= 100;
      })
      .required()
      .default(undefined),
    document: yup
      .string()
      .when('nationality', {
        is: value => value === 'BRA',
        then: yup
          .string()
          .test({
            test: function (value) {
              return value.replace(/\D/g, '').length < 11 || !cpfIsValid(value)
                ? this.createError({
                    message: 'error.invalid',
                  })
                : true;
            },
          })
          .transform(value => value.replace(/[^a-zA-Z0-9]/g, ''))
          .required(),
      })
      .transform(value => value.replace(/[^a-zA-Z0-9]/g, ''))
      .required()
      .default(undefined),
    marital_status: yup.number().required(),
    gender: yup.string().required(),
    ethnicity: yup.number().required(),
    has_disability: yup.bool().required(),
    disability: yup.number().when('has_disability', {
      is: true,
      then: yup.number().required(),
    }),
    uses_device: yup.boolean().when('has_disability', {
      is: true,
      then: yup.boolean().required(),
    }),
    has_medical_report: yup.boolean().when('has_disability', {
      is: true,
      then: yup.boolean().required(),
    }),
    // medical_report: yup.string().when('has_medical_report', {
    //   is: true,
    //   then: yup.string().required()
    // }),
  })
  .required();

const step2 = yup
  .object({
    college: yup.number().required(),
    course: yup.number().required(),
    course_duration: yup.number().required(),
    educational_level: yup.number().required(),
    conclusion_prevision_month: yup.string().required(),
    conclusion_prevision_year: yup.string().required(),
    study_period: yup.number().required(),
    record_student: yup
      .string()
      .transform(value => {
        return value.trim();
      })
      .test('max', 'error.record_student.max', value => {
        return value ? value.length < 16 : true;
      }),
  })
  .required();

const step3 = yup
  .object({
    zip_code: yup.string().when('country', {
      is: value => value === 'BRA' || value === undefined,
      then: yup
        .string()
        .test({
          test: function (value) {
            return value.replace(/\D/g, '').length !== 8
              ? this.createError({
                  message: 'error.invalid',
                })
              : true;
          },
        })
        .required(),
    }),
    address: yup.string().min(3).required(),
    number: yup.string().required(),
    complement: yup.string().nullable(),
    city: yup.number().required(),
    state: yup.string().required(),
    country: yup.string().required(),
    search_zip_code: yup.string().nullable(),
    neighborhood: yup
      .string()
      .transform(value => {
        return value.trim();
      })
      .test('max', 'error.neighborhood.max', value => {
        return value ? value.length < 65 : true;
      })
      .required(),
  })
  .required();

const NotFoundMyEducationalInstitution = yup.object({
  name_college_not_found: yup.string().required(),
});

export {
  authenticate,
  config,
  formMfa,
  reactive,
  recoveryEmail,
  recoveryPassword,
  register,
  removeMfa,
  resend,
  resetPassword,
  step1,
  step2,
  step3,
  NotFoundMyEducationalInstitution,
};
