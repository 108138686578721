import { Result as ResultCore, Link as LinkCore } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 80%;
      h1 {
        &.ant-typography {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Result = styled(ResultCore)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      margin: 40px 0;
      padding: 24px;
      border-radius: ${theme.borderRadius};
      .ant-result-icon {
        margin-bottom: 0;
        padding-bottom: 16px;
        ${mixins.animation('bounce-top', '1s')};
        svg {
          &.svg-inline--fa {
            height: 48px;
          }
        }
      }
    `;
  }}
`;

export const Link = styled(LinkCore)`
  ${({ theme }) => {
    return css`
      ${mixins.animation('fade-in', '0.3s')};
      display: flex;
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizeP};
      font-weight: 400;
      line-height: 20px;
    `;
  }}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
`;

export const Text = styled.div`
  ${({ theme }) => {
    return css`
      ${mixins.animation('fade-in', '0.3s')};
      color: ${theme.gray8};
      font-family: ${theme.fontFamily};
      font-size: ${theme.fontSizeP};
      font-weight: 400;
      line-height: 20px;
      padding-bottom: 24px;
    `;
  }}
`;
