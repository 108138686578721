import {
  Duration,
  add,
  differenceInDays,
  differenceInYears,
  format,
  intervalToDuration,
  max,
  min,
  parseISO,
} from 'date-fns';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt-BR';
import { pluralize, zeroPad } from './string';

const locale = {
  pt,
  en,
  es,
};

type ValidDateType = Date | number | string;

/**
 * Parses the parameter to a Date.
 */
function parseDate(date: ValidDateType) {
  if (typeof date === 'string' && /[\d]{2}\/[\d]{2}\/[\d]{4}/.test(date)) {
    date = date.split('/').reverse().join('-');
  } else if (typeof date === 'number') {
    return new Date(date);
  }

  return date instanceof Date ? date : parseISO(date);
}

/**
 * format seconds to hh:mm:ss.
 * @example
 * const result = formatDuration(305)
 * //=> 00:05:05
 */
function formatDuration(count: number) {
  const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: count * 1000 });
  
  return `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`;
}

/**
 * Adds the specified duration to the given date.
 * @example
 * // Add the following duration to 1 September 2014, 10:19:50
 * const result = add(new Date(2014, 8, 1, 10, 19, 50), {
 *   years: 2,
 *   months: 9,
 *   weeks: 1,
 *   days: 7,
 *   hours: 5,
 *   minutes: 9,
 *   seconds: 30,
 * })
 * //=> Thu Jun 15 2017 15:29:20
 */
function addToDate(date: ValidDateType, duration: Duration) {
  const parsedDate = parseDate(date);
  return add(parsedDate, duration);
}

function calculateAge(dateLeft: ValidDateType, dateRight: ValidDateType) {
  const parsedDateLeft = parseDate(dateLeft);
  const parsedDateRight = parseDate(dateRight);

  return differenceInYears(parsedDateLeft, parsedDateRight);
}

/**
 * Formats the date with a default pattern.
 */
function formatDateComplete(date: ValidDateType) {
  return formatDateTime(date, 'EEEE, dd MMMM yyyy');
}

/**
 * Formats the date with a default pattern.
 */
function formatDatePartial(date: ValidDateType) {
  return formatDateTime(date, "dd, dd 'de' MMMM");
}

/**
 * Formats the date as specified in the pattern.
 */
function formatDateTime(date: ValidDateType, pattern: string) {
  return format(parseDate(date), pattern);
}

/**
 * Formats the date with a default pattern.
 */
function formatDate(date: ValidDateType) {
  return formatDateTime(date, 'dd/MM/yyyy');
}

/**
 * Formats the minutes.
 * @example formatMinute(75) // 1h 15min
 */
function formatMinutes(minutes: number) {
  const hours = Math.floor(minutes / 60);

  minutes %= 60;

  return `${hours}h ${minutes}min`;
}

/**
 * Formats the time with a default pattern.
 */
function formatTime(date: ValidDateType) {
  return formatDateTime(date, 'HH:mm');
}

/**
 * Returns the date formatted with language passed as a parameter as an argument.
 * For more standard formats, check here:
 * https://date-fns.org/v2.29.2/docs/format
 * @example
 * getLocaleFormat(new Date(0, 5), 'LLLL', 'pt') // => 'Junho'
 * getLocaleFormat(new Date(0, 5), 'LLLL', 'en') // => 'June'
 * getLocaleFormat(new Date(2010, 5, 4), 'YYYY-LL-DD', 'pt') // => '2010-06-04'
 */
 function getLocaleFormat(
  value: number | Date,
  pattern: string,
  lang: string = 'pt',
) {
  const formatedDate = format(value, pattern, {
    locale: locale[lang],
  });

  return formatedDate.charAt(0).toUpperCase() + formatedDate.slice(1);
}

/**
 * Returns The daily stay text computed from the dates passed as parameters.
 * @example
 * const endDate = '2021-09-05'
 * const startDate = '2021-08-30'
 * const dailyStay = getDailyStay (endDate, startDate, 'day')
 * // 7 days
 */
function getDailyStay(
  endDate: ValidDateType,
  startDate: ValidDateType,
  dailyStayWord: string,
) {
  const totalDays = differenceInDays(parseDate(endDate), parseDate(startDate));

  return pluralize(dailyStayWord, Number(totalDays), true);
}

/**
 * Returns the latest of the given dates.
 * @example
 * const dates = [new Date(1989, 6, 10), new Date(1987, 1, 11)]
 * const maxDate = getMaxDate(dates)
 */
function getMaxDate(dates: ValidDateType[]) {
  const parsedDates = dates.map(date => parseDate(date));
  return max(parsedDates);
}

/**
 * Returns the earliest of the given dates.
 * @example
 * const dates = [new Date(1989, 6, 10), new Date(1987, 1, 11)]
 * const minDate = getMinDate(dates)
 */
function getMinDate(dates: ValidDateType[]) {
  const parsedDates = dates.map(date => parseDate(date));
  return min(parsedDates);
}

export {
  addToDate,
  calculateAge,
  formatDate,
  formatDateComplete,
  formatDatePartial,
  formatDateTime,
  formatDuration,
  formatMinutes,
  formatTime,
  getDailyStay,
  getLocaleFormat,
  getMaxDate,
  getMinDate,
  parseDate,
};
