import styled from '@emotion/styled';

export const Wrapper = styled.div`
  height: 100%;
  & > .ant-row {
    height: 100%;
    box-shadow: -2px -2px rgba(0, 0, 0, 0.2);
    justify-content: center;
  }
  .custom-datepicker {
    .ant-picker-panel {
      border-radius: 8px;
    }
  }

  .ant-btn-primary.btn_help_button{
    font-weight: 700;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    border-radius: 100rem;
    font-size: 1.07143rem;
    padding: 0.7rem 1.3rem;
    cursor: pointer;
    display: flex;
    gap: 5px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.6px;
    box-shadow: -2px -2px 2px 1px rgba(0, 0, 0, 0.2);
    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }
`;
