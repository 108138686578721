import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorBoundary, Field } from '@/components';
import { Text, Button, Input, Form } from '@/core';
import { Email as IconEmail } from '@/lib/assets/svg';
import Response from '@/lib/model/api/response';
import {
  useLoginMutation,
  useSendMfaMutation,
} from '@/lib/service/authenticate';
import { setForm as setAuthenticateForm } from '@/lib/store/authenticate';
import { setStep } from '@/lib/store/config';
import { setForm as setFormReactive } from '@/lib/store/reactive';
import { formMfa as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const FormMfa = () => {
  const dispatch = useDispatch();
  const state = useSelector(({ authenticate }) => authenticate);
  const [errorMessage, setErrorMessage] = useState('');
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [sendMfa, { isLoading: isSendMfaLoading }] = useSendMfaMutation();

  const { t } = useTranslation();

  const isLoading = isLoginLoading || isSendMfaLoading;

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const setMessage = message => {
    setErrorMessage(message);
    setTimeout(() => {
      setErrorMessage('');
    }, 3000);
  };

  const onSubmit = async data => {
    login(data).then((response: Response) => {
      if (!response.error) {
        // eslint-disable-next-line no-restricted-globals
        location.href = response.data.url;
      } else if (response.error.status === 400) {
        setMessage(t('api.error.user.token'));
      } else if (response.error.status === 401) {
        setMessage(t('error.authorization'));
        setTimeout(() => {
          dispatch(setStep('login'));
        }, 3000);
      } else if (response.error.status === 404) {
        dispatch(setFormReactive({ identity: data.identity }));
        dispatch(setStep('reactivate_account'));
      } else if (response.error.status === 409) {
        setMessage(t('error.authorization_conflict'));
      } else {
        setMessage(t('error.authorization'));
        setTimeout(() => {
          dispatch(setStep('login'));
        }, 3000);
      }
    });
  };

  const { identity, mfa_alternative, tokenSent } = state;

  const handleSendMfa = () => {
    sendMfa({ email: identity }).then((response: Response) => {
      if (!response.error) {
        dispatch(
          setAuthenticateForm({
            tokenSent: true,
          }),
        );
      } else if (response.error.status === 400) {
        setMessage(t('api.error.token.sent'));
      }
    });
  };

  const mfaAlternative = {
    code: mfa_alternative === 'sms' ? 'SMS' : t('form_mfa.email'),
    type:
      mfa_alternative === 'sms' ? t('form_mfa.number') : t('form_mfa.email'),
  };

  return (
    <S.Wrapper>
      <Text textType="title" level={1} style={{ marginTop: 40 }}>
        {tokenSent
          ? t('form_mfa.title_send_code', mfaAlternative)
          : t('form_mfa.title')}
      </Text>
      <S.Subtitle>
        <Text>{t('form_mfa.subtitle')}</Text>
      </S.Subtitle>
      {tokenSent && (
        <S.Result
          icon={<IconEmail />}
          subTitle={
            <Text className="ant-result-subtitle-text">
              {t('form_mfa.result_message', mfaAlternative)}
            </Text>
          }
        />
      )}
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Form type="item" label={t('form_mfa.code')}>
          <Field
            Component={Input}
            control={control}
            errors={errors}
            name="mfa"
            withPlaceholder={false}
          />
        </Form>
        {!tokenSent && (
          <>
            <Button
              block
              type="link"
              onClick={() => dispatch(setStep('remove_mfa'))}
            >
              {t('form_mfa.remove')}
            </Button>
            <Button block type="link" onClick={handleSendMfa}>
              {t('form_mfa.code_receive', mfaAlternative)}
            </Button>
          </>
        )}
        <Button
          block
          loading={isLoading}
          size="large"
          type="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
        >
          {t('form_mfa.button')}
        </Button>
      </Form>
      {!!errorMessage && <ErrorBoundary message={errorMessage} type="info" />}
    </S.Wrapper>
  );
};

export default FormMfa;
