import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Text } from '@/core';
import { Trophy } from '@/lib/assets/svg';
import * as S from './styles';

const Confirmation = () => {
  const { t } = useTranslation();
  const { confirmation } = useSelector(({ confirmation }) => ({
    confirmation,
  }));

  const { url, timeout } = confirmation;

  const [count, setCount] = useState(timeout / 1000);

  useEffect(() => {
    const timer = count > 0 && setInterval(() => setCount(count - 1), 1000);
    return () => clearInterval(timer);
  }, [count]);

  setTimeout(() => {
    if (
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== 'recrutamento.ciadeestagios.com.br'
    ) {
      // eslint-disable-next-line no-restricted-globals
      location.href = url;
    }
  }, timeout);

  return (
    <S.Wrapper>
      <S.Title>
        <Text textType="title" level={1} data-test="title-confirmation">
          {t('confirmation.title')}
        </Text>
      </S.Title>
      <S.Result icon={<Trophy />} subTitle={t('confirmation.congrats')} />
      <S.SubTitle>
        <Text textType="title" level={1}>
          {t('confirmation.subtitle')}
          <span>{t('confirmation.emphasis')}</span>.
        </Text>
      </S.SubTitle>
      <S.Link href={url}>{t('confirmation.login')}</S.Link>
    </S.Wrapper>
  );
};

export default Confirmation;
