import { Text, Button, Select as AntdSelect, Form } from '@/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Select = styled(AntdSelect)`
  width: 100%;
`;

export const Item = styled(Form)`
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      border-top-right-radius: 20px;
      bottom: 0;
      box-shadow: 1px 1px 7px 1px #000000;
      padding: 36px;
      position: fixed;
      left: 0;
      width: 350px;
      z-index: 20;
      max-height: 500px;
      overflow-x: auto;
      & > .ant-row {
        flex-direction: column;
      }
    `;
  }}
`;

export const TabWrapper = styled.div`
  ${({ theme }) => {
    return css`
      border-radius: ${theme.borderRadius};
      border: 1px solid #ccc;
      margin: 20px 0;
      padding: 10px;
    `;
  }}
`;

export const Show = styled.div`
  background-color: #ffffff;
  bottom: 0;
  box-shadow: 1px 1px 7px 1px #000000;
  left: 0;
  position: fixed;
  z-index: 20;
`;

export const Close = styled.div`
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const Tab = styled(Text)`
  width: auto;
`;

export const AreaTab = styled(Button)`
  width: 280px;
`;

export const DivReactQuery = styled.div`
  bottom: 0;
  position: fixed;
  right: 0;
`;
