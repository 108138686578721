import { Result as ResultCore } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 80%;
      h1 {
        &.ant-typography {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          padding-bottom: 16px;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }};
`;

export const Subtitle = styled.div`
  ${({ theme }) => {
    return css`
      display: inline-flex;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 40px;
      font-family: ${theme.fontFamily};
      .ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray9};
      }
      a.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.linkColor};
      }
      .ant-btn-link {
        line-height: 18px;
      }
      .ant-typography {
        margin-bottom: 0;
      }
    `;
  }};
`;

export const Result = styled(ResultCore)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      margin: 40px 0;
      padding: 24px;
      border-radius: ${theme.borderRadius};
      .ant-result-icon {
        margin-bottom: 0;
        padding-bottom: 16px;
        svg {
          ${mixins.animation('bounce-top', '1s')};
          &.svg-inline--fa {
            height: 64px;
          }
        }
      }
    `;
  }}
`;

export const Info = styled.div`
  ${({ theme }) => {
    return css`
      font-size: ${theme.fontSizeP};
      line-height: 20px;
      font-weight: 400;
      font-family: ${theme.fontFamily}, sans-serif;
      color: ${theme.gray8};
      div.ant-typography {
        margin-bottom: 0;
        ${mixins.animation('fade-in', '0.2s')};
        padding-bottom: 24px;
      }
      .ant-btn-link {
        line-height: 18px;
      }
    `;
  }}
`;
