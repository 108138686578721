/* eslint-disable react/no-array-index-key */
import { useSelector } from 'react-redux';
import Alert from '@/core/Alert';
import { flatten } from '@/lib/utils/array';

const FormFeedBack = () => {
  const { feedback } = useSelector(({ config: { feedback } }) => ({
    feedback,
  }));
  const { type, message, errors } = feedback;
  let description;
  let jsonErrors;
  let jsonMenssages;

  if (message) {
    if (typeof message === 'string') {
      description = message;
    } else {
      const { data: obj } = message;
      jsonMenssages = flatten(obj);
      description = Object.keys(jsonMenssages).map((item, index) => {
        return <div key={index}>{jsonMenssages[item]}</div>;
      });
    }
  } else {
    try {
      const {
        data: {
          errors: [{ data: obj }],
        },
      } = errors;
      if (typeof obj === 'string') {
        throw new Error('badly_formatted');
      }
      jsonErrors = flatten(obj);
    } catch (error) {
      jsonErrors = { 'response.badly_formatted': true };
    }
    description = Object.keys(jsonErrors).map((item, index) => {
      return <div key={index}>{jsonErrors[item]}</div>;
    });
  }

  return <Alert message={description} type={type} showIcon />;
};

export default FormFeedBack;
