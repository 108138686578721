import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, ErrorBoundary } from '@/components';
import { Text, Button, Select, Grid, Form, Steps } from '@/core';
import { GreenHeart, InfoIcon, Smile } from '@/lib/assets/svg';
import { getSteps } from '@/lib/enum';
import Response from '@/lib/model/api/response';
import {
  useGetStudyPeriodMutation,
  useGetCollegesMutation,
  useGetCoursesMutation,
  useGetEducationalLevelMutation,
} from '@/lib/service/autocomplete';
import { useStep2Mutation } from '@/lib/service/register';
import { setStep } from '@/lib/store/config';
import { setForm } from '@/lib/store/step2';
import { getLocaleFormat } from '@/lib/utils/date';
import { step2 as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import { NotFoundMyEducationalInstitution } from '../NotFoundMyEducationalInstitution';
import TooltipContent from '../tooltipContent';
import * as S from './styles';

const Step2 = () => {
  const dispatch = useDispatch();

  const [updateColleges, { isLoading: loadingColleges }] =
    useGetCollegesMutation();
  const [updateCourses, { data: courses, isLoading: loadingCourses }] =
    useGetCoursesMutation();
  const [
    updateEducationalLevel,
    { data: educationLevel, isLoading: loadingEducationLevel },
  ] = useGetEducationalLevelMutation();
  const [
    updateStudyPeriod,
    { data: studyPeriod, isLoading: loadingStudyPeriod },
  ] = useGetStudyPeriodMutation();

  const [step2, { isLoading, isError, error: step2Error }] = useStep2Mutation();
  const [collegeList, setCollegeList] = useState([]);

  const [showModal, setShowModal] = useState(false);

  let timer;

  const {
    authorization,
    errors: errorsState,
    ...state
  } = useSelector(({ step2, errors, config: { authorization } }) => ({
    ...step2,
    errors,
    authorization,
  }));

  const { t, i18n } = useTranslation();
  const items = getSteps();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setError,
    trigger,
    setValue,
    setFocus,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
      campus: undefined,
      course: undefined,
      college: undefined,
      course_duration: Number(state.course_duration) || undefined,
      conclusion_prevision_month: state.conclusion_prevision_month || undefined,
      conclusion_prevision_year: state.conclusion_prevision_year || undefined,
      study_period: undefined,
      record_student: undefined,
      educational_level: undefined,
      work_mode: 3,
    },
  });

  useEffect(() => {
    if (state.college) {
      updateColleges({ id: state.college, lang: i18n.language }).then(
        (response: Response) => {
          setValue('college', Number(state.college));
          setCollegeList(response.data);
          trigger('college');
        },
      );
    }

    if (state.course) {
      updateCourses({ id: state.course, lang: i18n.language }).then(() => {
        setValue('course', Number(state.course));
        trigger('course');
      });
    }

    updateStudyPeriod({ lang: i18n.language }).then(() => {
      if (state.study_period !== undefined) {
        setValue('study_period', Number(state.study_period));
        trigger('study_period');
      }
    });

    updateEducationalLevel({ lang: i18n.language }).then(() => {
      if (state.educational_level) {
        setValue('educational_level', Number(state.educational_level));
        trigger('educational_level');
      }
    });

    if (state.record_student) {
      setValue('record_student', state.record_student);
      trigger('record_student');
    }

    Object.entries(errorsState)
      .filter(([, { step, hasError }]: any) => step === 2 && hasError)
      .forEach(([key]) => {
        setError(
          key,
          {
            message: `error.${key}.apiError`,
            type: 'apiError',
          },
          {
            shouldFocus: true,
          },
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  const onSubmit = async payload => {
    if (payload.college === 1 && !state.name_college_not_found) {
      setShowModal(true);
    } else {
      const data = {
        ...payload,
        lang: i18n.language,
      };
      dispatch(
        setForm({
          name_college_not_found: state.name_college_not_found,
          ...data,
        }),
      );

      step2({
        ...data,
        authorization,
      }).then((response: Response) => {
        if (!response.error) {
          dispatch(setStep('step3'));
        }
      });
    }
  };

  const semesters = Array(6)
    .fill(null)
    .map((_, index) => ({
      label: `${t('step2.form.semester', {
        count: (index + 1) * 2,
        count_years: index + 1,
      })} ${t('step2.form.year', {
        count: index + 1,
      })}`,
      value: (index + 1) * 2,
    }));

  const conclusion_prevision_month = [
    {
      label: getLocaleFormat(new Date(0, 5), 'LLLL', i18n.language),
      value: '06',
    },
    {
      label: getLocaleFormat(new Date(0, 6), 'LLLL', i18n.language),
      value: '07',
    },
    {
      label: getLocaleFormat(new Date(0, 11), 'LLLL', i18n.language),
      value: '12',
    },
  ];

  const conclusion_prevision_year = Array(81)
    .fill(new Date().getFullYear())
    .map((value, index) => ({
      label: index < 10 ? value + index : value - (index - 9),
      value: index < 10 ? value + index : value - (index - 9),
    }));

  return (
    <S.Wrapper>
      <NotFoundMyEducationalInstitution
        visible={showModal}
        close={() => setShowModal(false)}
      />
      <S.StepContainer>
        <Steps
          current={1}
          items={items}
          onChange={index => {
            if (index < 1) {
              const data = getValues();
              dispatch(setForm(data));
              dispatch(setStep(`step${index + 1}`));
            }
          }}
        />
      </S.StepContainer>
      <S.Title>
        <Text textType="title" level={1} data-test="title-step">
          {t('step2.title')}
        </Text>
      </S.Title>
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <S.Grid>
          <Grid type="row">
            <Grid type="col" xs={{ span: 24 }}>
              <Form
                type="item"
                label={t('step2.form.educational_level')}
                tooltip={{
                  title: (
                    <TooltipContent
                      title={t('step2.form.tooltip.question')}
                      content={t('step2.form.tooltip.answer')}
                      emotion={[<GreenHeart />, <Smile />]}
                    />
                  ),
                  icon: (
                    <span
                      role="img"
                      aria-label="info-circle"
                      className="anticon anticon-info-circle ant-form-item-tooltip"
                    >
                      <InfoIcon />
                    </span>
                  ),
                  trigger: ['hover', 'click'],
                }}
              >
                <Field
                  name="educational_level"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: educationLevel,
                    placeholder: loadingEducationLevel
                      ? t('commons.loading')
                      : t('placeholder.educational_level'),
                    onSelect: () => {
                      setFocus('college');
                    },
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
          </Grid>
          <Grid type="row">
            <Grid type="col" span="24" order={1}>
              <Form type="item" label={t('step2.form.college')}>
                <Field
                  name="college"
                  control={control}
                  Component={Select}
                  compProps={{
                    defaultActiveFirstOption: false,
                    filterOption: false,
                    fetching: loadingColleges,
                    options: collegeList,
                    showArrow: true,
                    showSearch: true,
                    onSelect: id => {
                      if (id === 1) {
                        setShowModal(true);
                      }
                      setFocus('course');
                    },
                    onKeyUp: evt => {
                      const fantasy_name = evt.target.value;
                      if (fantasy_name.length > 2) {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          updateColleges({
                            fantasy_name,
                            lang: i18n.language,
                          }).then((response: Response) => {
                            if (response.data.length > 0) {
                              const newList = response.data.map(item => {
                                if (item.value === 1) {
                                  return {
                                    value: item.value,
                                    label: t('step2.form.college_not_found'),
                                  };
                                }
                                return item;
                              });
                              setCollegeList(newList);
                            }
                          });
                        }, 500);
                      }
                    },
                    onKeyPress: () => {
                      clearTimeout(timer);
                    },
                    placeholder: loadingColleges
                      ? t('commons.loading')
                      : t('placeholder.college'),
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
          </Grid>
          <Grid type="row">
            <Grid type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step2.form.course')}>
                <Field
                  name="course"
                  control={control}
                  Component={Select}
                  compProps={{
                    defaultActiveFirstOption: false,
                    filterOption: false,
                    fetching: loadingCourses,
                    options: courses,
                    showArrow: false,
                    showSearch: true,
                    onSelect: () => {
                      setFocus('course_duration');
                    },
                    onKeyUp: evt => {
                      const name = evt.target.value;
                      if (name.length > 2) {
                        clearTimeout(timer);
                        timer = setTimeout(() => {
                          updateCourses({ name, lang: i18n.language });
                        }, 500);
                      }
                    },
                    onKeyPress: () => {
                      clearTimeout(timer);
                    },
                    placeholder: loadingCourses
                      ? t('commons.loading')
                      : t('placeholder.course'),
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid type="col" xs={{ span: 24 }}>
              <Form type="item" label={t('step2.form.course_length')}>
                <Field
                  name="course_duration"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: semesters,
                    onSelect: () => {
                      setFocus('study_period');
                    },
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
          </Grid>
          <Grid type="row">
            <Grid
              type="col"
              xs={{
                span: 24,
                offset: 0,
              }}
            >
              <Form type="item" label={t('step2.form.study_period')}>
                <Field
                  name="study_period"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: studyPeriod,
                    placeholder: loadingStudyPeriod
                      ? t('commons.loading')
                      : t('placeholder.study_period'),
                    onSelect: () => {
                      setFocus('conclusion_prevision_month');
                    },
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
            <Grid
              type="col"
              xs={{ span: 24 }}
              lg={{
                span: 14,
              }}
            >
              <Form
                type="item"
                label={t('step2.form.conclusion_prevision_month')}
              >
                <Field
                  name="conclusion_prevision_month"
                  control={control}
                  Component={Select}
                  compProps={{
                    filterOption: (inputValue, option) =>
                      option!.label
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1,
                    options: conclusion_prevision_month,
                    onSelect: () => {
                      setFocus('conclusion_prevision_year');
                    },
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>

            <Grid
              type="col"
              xs={{
                span: 24,
              }}
              lg={{
                span: 9,
                offset: 1,
              }}
            >
              <Form
                type="item"
                label={t('step2.form.conclusion_prevision_year')}
              >
                <Field
                  name="conclusion_prevision_year"
                  control={control}
                  Component={Select}
                  compProps={{
                    options: conclusion_prevision_year,
                    showSearch: true,
                  }}
                  errors={errors}
                />
              </Form>
            </Grid>
          </Grid>
        </S.Grid>
        <Button
          block
          loading={isLoading}
          type="primary"
          size="large"
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          data-test="submit_step2_button"
        >
          {t('commons.next')}
        </Button>
      </Form>
      {isError && <ErrorBoundary errors={step2Error} />}
    </S.Wrapper>
  );
};

export default Step2;
