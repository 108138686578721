const flatten = (obj = {}, res = {}, extraKey = '') => {
  try {
    for (let key in obj){
      if (typeof obj[key] !== 'object') {
        res[extraKey + key] = obj[key];
      } else {
        flatten(obj[key], res, `${extraKey}${key}.`);
      };
    };
    return res;
  } catch (_) {
    return 'transform';
  }
};

export default flatten;
