import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from '@/components';
import { Button, Form, Input, Text } from '@/core';
import Response from '@/lib/model/api/response';
import { useCollegeNotFoundMutation } from '@/lib/service/register';
import { setFeedback } from '@/lib/store/config';
import { setForm } from '@/lib/store/step2';
import { NotFoundMyEducationalInstitution as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import FormFeedBack from '../formFeedback';
import * as S from './styles';

type NotFoundMyEducationalInstitutionProps = {
  visible: boolean;
  close: () => void;
};

const initialState = {
  message: undefined,
  type: undefined,
  errors: undefined,
  show: false,
};

export const NotFoundMyEducationalInstitution: FC<NotFoundMyEducationalInstitutionProps> =
  ({ visible, close }) => {
    const dispatch = useDispatch();
    const [collegeNotFound, { isLoading }] = useCollegeNotFoundMutation();
    const { t } = useTranslation();

    const { authorization, feedback } = useSelector(
      ({ config: { authorization, feedback } }) => ({
        authorization,
        feedback,
      }),
    );

    const setMessage = feedbackMessage => {
      dispatch(setFeedback(feedbackMessage));
    };

    const {
      control,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
    } = useForm({
      mode: 'onChange',
      resolver: yupResolver(schema),
      defaultValues: {
        name_college_not_found: undefined,
      },
    });

    const onSubmit = async data => {
      setMessage(initialState);
      dispatch(setForm(data));
      collegeNotFound({ authorization, payload: data }).then(
        (response: Response) => {
          if (!response.error) {
            close();
          } else {
            /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
            try {
              setMessage({
                message: t('error.college_not_found.apiError'),
                type: 'error',
                errors: undefined,
                show: true,
              });
            } catch {}
          }
        },
      );
    };

    return (
      <S.Modal
        visible={visible}
        centered
        footer={null}
        onCancel={() => {
          close();
          setMessage(initialState);
          setValue('name_college_not_found', '');
        }}
        data-test="registerNewCollegeModal"
      >
        <Text textType="title" level={1}>
          {t('name_college_not_found.title')}
        </Text>
        <S.DescriptionWrapper>
          <Text textType="text" className="modal-description">
            {t('name_college_not_found.description')}
          </Text>
        </S.DescriptionWrapper>
        <Form
          layout="vertical"
          initialValues={{
            layout: 'vertical',
          }}
        >
          <Form type="item" label={t('name_college_not_found.field_label')}>
            <Field
              name="name_college_not_found"
              control={control}
              Component={Input}
              errors={errors}
            />
          </Form>
          {feedback.show && (
            <S.FeedbackContainer type="row">
              <FormFeedBack />
            </S.FeedbackContainer>
          )}
          <Button
            block
            type="primary"
            size="large"
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            loading={isLoading}
            data-test="sendNewCollegeButton"
          >
            {t('name_college_not_found.submit_label')}
          </Button>
        </Form>
      </S.Modal>
    );
  };
