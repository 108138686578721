import { useEffect } from 'react';
/*
import { UploadProps } from 'antd';
*/
import { useTranslation } from 'react-i18next';
import { Field } from '@/components';
import {
  Form,
  Grid,
  Select,
  Radio /* , Upload, Button, Text, Icon */,
} from '@/core';
import { useGetDisabilityMutation } from '@/lib/service/autocomplete';
import * as S from './styles';

const HasDisability = ({
  control,
  options,
  errors,
  setValue /* , watch */,
  state,
}) => {
  const { t, i18n } = useTranslation();

  const [updateDisability, { data: disabilities }] = useGetDisabilityMutation();

  useEffect(() => {
    updateDisability({ lang: i18n.language }).then(() => {
      if (state.disability) {
        setValue('disability', Number(state.disability));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  /*
  const [showContent, setShowContent] = useState(false);
  const maxCount = 2;
  const props: UploadProps = {
    name: 'file',
    multiple: true,
    accept: 'image/png,image/jpg,.pdf',
    maxCount,
    onChange: file => {
      setShowContent(file.fileList.length === maxCount);
    },
  };
  */

  return (
    <>
      <Grid type="col" xs={{ span: 24, order: 5 }} md={{ span: 14, order: 5 }}>
        <Form type="item" label={t('step1.form.disability')}>
          <Field
            name="disability"
            control={control}
            Component={Select}
            compProps={{
              filterOption: (inputValue, option) =>
                option!.label
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1,
              options: disabilities,
              showSearch: true,
            }}
            errors={errors}
          />
        </Form>
      </Grid>
      <Grid type="col" xs={{ span: 11, order: 5 }} xxl={{ span: 7, order: 5 }}>
        <S.FormLabel
          type="item"
          label={t('step1.form.use_device')}
          className="form-item-equipment"
        />
      </Grid>
      <Grid type="col" order={5} xs={{ span: 6 }} sm={{ span: 4 }}>
        <S.FormRadio type="item">
          <Field
            Component={Radio}
            compProps={{
              onChange: e =>
                setValue('uses_device', e.target.checked, {
                  shouldValidate: true,
                  shouldDirty: true,
                }),
              options,
            }}
            control={control}
            errors={errors}
            name="uses_device"
          />
        </S.FormRadio>
      </Grid>
      <Grid type="col" xs={{ span: 11, order: 5 }} xxl={{ span: 7, order: 5 }}>
        <S.FormLabel
          type="item"
          label={t('step1.form.has_report')}
          className="form-item-report"
        />
      </Grid>
      <Grid type="col" order={5} xs={{ span: 6 }} sm={{ span: 4 }}>
        <S.FormRadio type="item">
          <Field
            Component={Radio}
            compProps={{
              onChange: e =>
                setValue('has_medical_report', e.target.checked, {
                  shouldValidate: true,
                  shouldDirty: true,
                }),
              options,
            }}
            control={control}
            errors={errors}
            name="has_medical_report"
          />
        </S.FormRadio>
      </Grid>
      {/* {watch('has_medical_report') === 1 && (
        <S.GridUpload type="col">
          <Form type="item">
            <S.CardUpload>
              <Upload listType="picture" {...props}>
                {!showContent && (
                  <>
                    <Text>{t('step1.form.upload_guidance')}</Text>
                    <Button icon={<Icon iconName="UploadOutlined" />}>
                      {t('step1.form.attach_report')}
                    </Button>
                    <Text>{t('step1.form.upload_type_file')}</Text>
                  </>
                )}
              </Upload>
            </S.CardUpload>
          </Form>
        </S.GridUpload>
      )} */}
    </>
  );
};
export default HasDisability;
