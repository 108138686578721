import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorBoundary, Field } from '@/components';
import { Form, Text, Button, Input, Grid } from '@/core';
import { Email as IconEmail } from '@/lib/assets/svg';
import Response from '@/lib/model/api/response';
import { useRecoveryAccountMutation } from '@/lib/service/authenticate';
import { setForm } from '@/lib/store/resend';
import { recoveryEmail as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const RecoveryEmail = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const state = useSelector(({ recoveryEmail }) => ({
    ...recoveryEmail,
  }));

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
    },
  });

  const [recoveryAccount, { isLoading, isError, error: resetError }] =
    useRecoveryAccountMutation();

  const [sendEmail, setSendEmail] = useState(false);

  const onSubmit = data => {
    setValue('role', 'student');
    dispatch(setForm(data));
    recoveryAccount(data).then((response: Response) => {
      if (!response.error) {
        setSendEmail(true);
      }
    });
  };

  useEffect(() => {
    setValue('lang', i18n.language);
  }, [setValue, i18n.language]);

  return (
    <S.Wrapper>
      <Text textType="title" level={1} style={{ marginTop: 40 }}>
        {t('recovery.email.title')}
      </Text>
      {sendEmail === false ? (
        <Grid type="col">
          <Text textType="text" className="text-instructions">
            {t('recovery.email.information')}
          </Text>
          <Form
            layout="vertical"
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Form type="item" label={t('recovery.email.document')}>
              <Field
                Component={Input}
                compProps={{
                  value: state.value,
                  Placeholder: t('recovery.email.document_placeholder'),
                }}
                control={control}
                errors={errors}
                name="document"
              />
            </Form>
            <Form type="item" label={t('recovery.email.password')}>
              <Field
                Component={Input}
                compProps={{
                  value: state.value,
                  Placeholder: t('recovery.email.password_placeholder'),
                  type: 'password',
                }}
                control={control}
                errors={errors}
                name="password"
              />
            </Form>
            <Form type="item" label={t('recovery.email.new_email')}>
              <Field
                Component={Input}
                compProps={{
                  value: state.value,
                  Placeholder: t('recovery.email.new_email_placeholder'),
                }}
                control={control}
                errors={errors}
                name="email"
              />
            </Form>
            <Form type="item" label={t('recovery.email.confirm_new_email')}>
              <Field
                Component={Input}
                compProps={{
                  value: state.value,
                  Placeholder: t(
                    'recovery.email.confirm_new_email_placeholder',
                  ),
                }}
                control={control}
                errors={errors}
                name="confirm_email"
              />
            </Form>
            <Button
              block
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              size="large"
              type="primary"
              disabled={!isValid}
            >
              {t('recovery.email.send_email')}
            </Button>
          </Form>
        </Grid>
      ) : (
        <S.Result
          icon={<IconEmail />}
          subTitle={<Text>{t('recovery.email.response_sent')}</Text>}
        />
      )}
      {isError && <ErrorBoundary errors={resetError} />}
    </S.Wrapper>
  );
};

export default RecoveryEmail;
