import { Grid as AntdGrid, Button, Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 300px;
      ${mixins.animation('fade-in', '0.2s')};

      h1 {
        &.ant-typography {
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          width: 159px;
        }
      }
      .ant-form-item-label {
        padding-bottom: 0;
      }
      .select-ddi {
        border: 0;
        div.ant-select-selector {
          border: 0;
          padding: 0;
          height: 27px;
          width: 56px !important;
          span.ant-select-selection-item {
            font-size: 22px;
            border-radius: 8px;
            width: 60px;
          }
          div {
            svg {
              width: 70%;
            }
          }
        }
        div.ant-select-selector ~ .ant-select-arrow {
          right: 2px;
        }
      }
      .ant-btn-link {
        white-space: break-spaces;
        text-align: left;
        height: auto;
        width: 100%;
      }
      .ant-btn {
        &.ant-btn-primary {
          margin-top: 16px;
        }
      }

      .ant-input-affix-wrapper {
        & > input.ant-input {
          margin: 0;
        }
        .ant-input-show-count-suffix {
          position: absolute;
          right: 0;
          top: 100%;
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: 12px;
        }
      }

      .ant-input-affix-wrapper-status-error:has(> #neighborhood)
        ~ .ant-typography {
        max-width: 180px;
      }

      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
`;

export const StepContainer = styled.div`
  margin-bottom: 40px;
`;

export const Gridlink = styled(AntdGrid)`
  ${({ theme }) => {
    return css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .ant-btn-link {
        font-size: ${theme.fontSizeP};
        font-weight: 600;
        font-style: normal;
      }
    `;
  }}
`;

export const ShowZipCode = styled(Button)`
  ${({ theme }) => {
    return css`
      display: flex;
      gap: 10px;
      &.ant-btn-link {
        font-size: 16px;
        svg {
          height: 24px;
          path {
            fill: ${theme.primaryGreen};
          }
        }
      }
    `;
  }}
`;

export const Info = styled(Text)`
  ${() => {
    return css`
      font-size: 16px;
    `;
  }}
`;
