import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${mixins.animation('fade-in', '0.2s')};
  display: flex;
  align-items: center;
  margin: 40px auto;
  @media only screen and (min-width: 1024px) {
    width: 480px;
    margin: 40px auto 40px 80px;
  }
  @media only screen and (min-width: 2560px) {
    margin: 40px auto 40px 200px;
  }
`;

export const AlignedContainer = styled.div`
  ${({ theme }) => {
    return css`
      width: 200px;
      margin: 0 auto;
      text-align: center;
      .ant-spin {
        .ant-spin-dot {
          width: auto;
        }
      }
      h2 {
        padding-top: 16px;
        color: ${theme.gray8};
      }
    `;
  }}
`;
