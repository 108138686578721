import { Card as AntdCard, Form as AntdForm, Grid as AntdGrid } from '@/core';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const CardUpload = styled(AntdCard)`
  ${({ theme }) => {
    return css`
      text-align: center;
      border: 1px solid ${theme.whiteBackground};
      padding: 24px;
      .ant-card-body {
        padding: 0;
        display: flex;
        justify-content: center;
        flex-flow: column wrap;
        .ant-typography {
          font-size: ${theme.fontSizeP};
          color: ${theme.gray8};
          margin: 0;
        }
        .ant-btn {
          margin: 12px 0;
        }
        & > span {
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          .ant-upload-list {
            .ant-upload-list-picture-container {
              &:last-child {
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    `;
  }}
`;

export const FormRadio = styled(AntdForm)`
  .ant-form-item-control,
  .form-item-equipment {
    flex-direction: row;
    justify-content: flex-end;
  }
`;
export const FormLabel = styled(AntdForm)`
  margin-bottom: 0;
  .ant-form-item-control,
  .form-item-equipment {
    display: none;
  }
`;
export const GridUpload = styled(AntdGrid)`
  width: 100%;
`;
