import { Result as ResultCore } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 80%;
      h1 {
        ${mixins.animation('fade-in', '0.2s')};
        color: ${theme.gray9};
        font-family: ${theme.fontFamily};
        font-size: ${theme.fontSizeH1Mobile};
        font-weight: 800;
        line-height: 32px;
        margin-bottom: 0;
        padding-bottom: 16px;
      }
      div.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        color: ${theme.gray8};
        display: inline-flex;
        font-family: ${theme.fontFamily}, sans-serif;
        font-size: ${theme.fontSizeP};
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 0;
      }
      div.ant-form-item-label {
        label {
          ${mixins.animation('fade-in', '0.2s')};
          line-height: 20px;
          font-weight: 400;
        }
      }
      form {
        ${mixins.animation('fade-in', '0.2s')};
        .ant-form-item {
          margin-bottom: 40px;
        }
      }
      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }}
`;

export const Result = styled(ResultCore)`
  ${({ theme }) => {
    return css`
      background-color: ${theme.whiteBackground};
      margin: 40px 0;
      padding: 24px;
      border-radius: ${theme.borderRadius};
      .ant-result-icon {
        margin-bottom: 0;
        padding-bottom: 16px;
        svg {
          ${mixins.animation('bounce-top', '1s')};
          &.svg-inline--fa {
            height: 64px;
          }
        }
      }
    `;
  }}
`;
