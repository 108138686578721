const fields = {
  cep: {
    fieldName: 'zip_code',
    step: 3
  },
  cidade: {
    fieldName: 'city',
    step: 3
  },
  complemento: {
    fieldName: 'complement',
    step: 3
  },
  curso: {
    fieldName: 'course',
    step: 2
  },
  deficiente: {
    fieldName: 'has_disability',
    step: 1
  },
  dtnasc: {
    fieldName: 'birth_date',
    step: 1
  },
  duracao_curso: {
    fieldName: 'course_duration',
    step: 2
  },
  escola: {
    fieldName:'college',
    step: 2
  },
  estadocivil: {
    fieldName:'marital_status',
    step: 1
  },
  etnia: {
    fieldName:'ethnicity',
    step: 1
  },
  formacao_aluno_ano: {
    fieldName:'conclusion_prevision_year',
    step: 2
  },
  formacao_aluno_mes: {
    fieldName: 'conclusion_prevision_month',
    step: 2
  },
  logradouro: {
    fieldName: 'address',
    step: 3
  },
  logrnum: {
    fieldName: 'number',
    step: 3
  },
  nacionalidade: {
    fieldName: 'nationality',
    step: 1
  },
  nivelensino: {
    fieldName: 'educational_level',
    step: 2
  },
  nome: {
    fieldName: 'full_name',
    step: 1
  },
  pais: {
    fieldName: 'country',
    step: 3
  },
  periodoaula: {
    fieldName: 'study_period',
    step: 2
  },
  rg: {
    fieldName: 'document',
    step: 1
  },
  senha: {
    fieldName: 'password',
    step: 1
  },
  sexo: {
    fieldName: 'gender',
    step: 1
  },
  telefone_cel_ddd: {
    fieldName: 'mobile_phone',
    step: 3
  },
  telefone_cel_ddi: {
    fieldName: 'mobile_phone',
    step: 3
  },
  telefone_cel: {
    fieldName: 'mobile_phone',
    step: 3
  },
  telefone_res_ddd: {
    fieldName: 'phone',
    step: 3
  },
  telefone_res_ddi: {
    fieldName: 'phone',
    step: 3
  },
  telefone_res: {
    fieldName: 'phone',
    step: 3
  },
  tipodeficiencia: {
    fieldName: 'disability',
    step: 1
  },
  uf: {
    fieldName: 'state',
    step: 3
  }
};

export default fields;