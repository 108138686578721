import { Button, Divider, Text } from '@/core';
import mixins from '@/lib/styles/mixins';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      margin: 40px auto;
      width: 80%;
      h1 {
        &.ant-typography {
          ${mixins.animation('fade-in', '0.2s')};
          color: ${theme.gray9};
          font-family: ${theme.fontFamily};
          font-size: ${theme.fontSizeH1Mobile};
          font-weight: 800;
          line-height: 32px;
          margin-bottom: 0;
          padding-bottom: 40px;
        }
      }
      .login-last-item {
        margin-bottom: 8px;
      }

      .btn-link-forget-email {
        margin-top: 9px;
      }

      @media only screen and (min-width: 768px) {
        width: 480px;
        padding: 0 16px;
        h1 {
          &.ant-typography {
            width: 100%;
          }
        }
      }
    `;
  }};
`;

export const Subtitle = styled.div`
  ${({ theme }) => {
    return css`
      margin-top: 40px;
      display: inline-flex;
      font-weight: 400;
      line-height: 20px;
      font-family: ${theme.fontFamily};
      width: 100%;
      justify-content: center;
      .ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray8};
      }
      a.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.linkColor};
      }
      .ant-btn-link {
        line-height: 18px;
        font-weight: 600;
      }
      .ant-typography {
        margin-bottom: 0;
      }

      flex-direction: column;
      align-items: center;

      @media only screen and (min-width: 376px) {
        flex-direction: row;
        gap: 0 4px;
      }
    `;
  }};
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  ${mixins.animation('fade-in', '0.2s')};
`;

export const SocialButton = styled(Button)`
  background: #fff;
  border: 0;
  border-radius: 4px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: baseline;
  padding: 12px 8px;
  margin-right: 10px;
  ${mixins.animation('fade-in', '0.2s')};
  &:last-child {
    margin-right: 0;
  }
`;

export const Div = styled(Divider)`
  ${mixins.animation('fade-in', '0.2s')};
  text-transform: uppercase;
  &.ant-divider-horizontal.ant-divider-with-text {
    margin: 24px 0;
  }
`;

export const Accept = styled.div`
  ${({ theme }) => {
    return css`
      margin: 0 0 40px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;
      label {
        ${mixins.animation('fade-in', '0.2s')};
        font-size: ${theme.fontSizeP};
        color: ${theme.gray8};
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: space-between;
      }
    `;
  }}
`;

export const TextSocialButtons = styled(Text)`
  ${({ theme }) => {
    return css`
      &.ant-typography {
        ${mixins.animation('fade-in', '0.2s')};
        color: ${theme.gray7};
        font-family: ${theme.fontFamily};
        margin-bottom: 8px;
      }
    `;
  }}
`;

export const ForgotPasswordButton = styled(Button)`
  ${() => {
    return css`
      &.ant-btn-link {
        font-weight: 600;
      }
    `;
  }}
`;
