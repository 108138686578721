import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BackIcon, Check, CloseCircle } from '@/lib/assets/svg';
import * as S from './styles';

type helperPasswordProps = {
  password: string;
};

const HelperPassword: FC<helperPasswordProps> = ({ password = '' }) => {
  const { t } = useTranslation();

  const [minLengthChar, setMinLengthChar] = useState('default');
  const [minLowercase, setMinLowercase] = useState('default');
  const [minNumber, setMinNumber] = useState('default');
  const [minSymbol, setMinSymbol] = useState('default');
  const [minUppercase, setUppercase] = useState('default');

  useEffect(() => {
    const stateMinLength = () => {
      return password.length >= 8 ? 'valid' : 'error';
    };

    const checkByRegex = regex => {
      if (regex.test(password)) {
        return 'valid';
      }
      return 'error';
    };
    if (password.length > 0) {
      setMinLengthChar(stateMinLength());
      setMinLowercase(checkByRegex(/[a-z]/));
      setMinNumber(checkByRegex(/[0-9]/));
      setMinSymbol(checkByRegex(/\W/));
      setUppercase(checkByRegex(/[A-Z|]/));
    } else {
      setMinLengthChar('default');
      setMinLowercase('default');
      setMinNumber('default');
      setMinSymbol('default');
      setUppercase('default');
    }
  }, [password]);

  const returnIcon = conditions => {
    if (conditions === 'valid') {
      return (
        <div>
          <Check />
        </div>
      );
    }
    if (conditions === 'error') {
      return (
        <div>
          <CloseCircle />
        </div>
      );
    }
    return (
      <div>
        <BackIcon />
      </div>
    );
  };

  return (
    <div>
      <S.List id="helper-password-list">
        <S.ListItem id="minUppercase" state={minUppercase}>
          {returnIcon(minUppercase)}
          {t(`error.password.minUppercase`)}
        </S.ListItem>
        <S.ListItem id="minLowercase" state={minLowercase}>
          {returnIcon(minLowercase)}
          {t(`error.password.minLowercase`)}
        </S.ListItem>
        <S.ListItem id="minSymbol" state={minSymbol}>
          {returnIcon(minSymbol)}
          {t(`error.password.minSymbol`)}
        </S.ListItem>
        <S.ListItem id="minNumber" state={minNumber}>
          {returnIcon(minNumber)}
          {t(`error.password.minNumber`)}
        </S.ListItem>
        <S.ListItem id="minLengthChar" state={minLengthChar}>
          {returnIcon(minLengthChar)}
          {t(`error.password.min`)}
        </S.ListItem>
      </S.List>
    </div>
  );
};

export default HelperPassword;
