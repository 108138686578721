/**
 * Cpf validator.
 */
function cpfIsValid(str: any) {
  if (typeof str !== 'string') {
  return false;
}
str = str.replace(/[^\d]+/g, '');
if (str.length !== 11 || !!str.match(/(\d)\1{10}/)) {
  return false;
}
str = str.split('').map(el => +el);
const rest = (count) => (str
  .slice(0, count-12)
  .reduce((soma, el, index) => (soma + el * (count - index)), 0) *10) % 11 % 10;
return rest(10) === str[9] && rest(11) === str[10];
}

export { cpfIsValid };