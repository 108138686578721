/* eslint no-console: ["error", { allow: ["log"] }] */
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Field, ErrorBoundary } from '@/components';
import {
  Text,
  Button,
  Input,
  Form,
  Checkbox,
  Modal,
  CountryPicker,
} from '@/core';
import dataLayerPush from '@/lib/dataLayer';
import Response from '@/lib/model/api/response';
import { useGetFlagsQuery } from '@/lib/service/autocomplete';
import { useReCAPTCHAMutation } from '@/lib/service/reCAPTCHA';
import { useRegisterMutation } from '@/lib/service/register';
import { useGetTermsQuery, useGetPolicyQuery } from '@/lib/service/search';
import { setAuthorization, setStep } from '@/lib/store/config';
import { setForm } from '@/lib/store/register';
import { setForm as setFormStep1 } from '@/lib/store/step1';
import { setForm as setFormStep2 } from '@/lib/store/step2';
import { register as schema } from '@/lib/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as S from './styles';

const Register = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { ...state } = useSelector(({ register }) => ({
    ...register,
  }));
  const [showModal, setShowModal] = useState(false);
  const [content, setContent] = useState('');
  const [errorRECAPTCHA, setErrorRECAPTCHA] = useState(false);
  const { data: policy } = useGetPolicyQuery({});
  const { data: terms } = useGetTermsQuery({});
  const [reCAPTCHALocal, setReCAPTCHA] = useState({ validate: '' });
  const [isTest, setIsTest] = useState(false);
  const [codeDialMobile, setCodeDialMobile] = useState('+(55)');

  const [register, { isLoading, isError, error: registerError }] =
    useRegisterMutation();

  const [reCAPTCHA] = useReCAPTCHAMutation();

  const { data: flags } = useGetFlagsQuery({});

  const { t, i18n } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    setFocus,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...state,
      mobile_phone_ddd: undefined,
      mobile_phone_ddi: undefined,
      mobile_phone: undefined,
    },
  });

  const changeDialCodeMobile = (value: string) => {
    setCodeDialMobile(`+(${value})`);
    setValue('mobile_phone', `+(${value})`);
    setFocus('mobile_phone');
  };

  const onSubmit = async data => {
    const {
      accept,
      social,
      lang,
      email,
      mobile_phone: { mobile_phone_ddd, mobile_phone_ddi, mobile_phone },
    } = data;
    const payload = {
      accept,
      social,
      lang,
      email,
      mobile_phone_ddd,
      mobile_phone_ddi,
      mobile_phone,
    };
    dispatch(setForm(payload));
    if (!isTest) {
      setErrorRECAPTCHA(false);
      reCAPTCHA(reCAPTCHALocal).then((response: Response) => {
        if (response.data.success) {
          register(payload).then((response: Response) => {
            if (!response.error) {
              if (process.env.NODE_ENV === 'production') {
                dataLayerPush({
                  event: 'gtm.startRegister',
                  'gtm.elementClasses':
                    document.querySelector('form').classList,
                  'gtm.elementUrl': window.location.href,
                  'gtm.email': email,
                  label: 'Cadastro de E-mail',
                });
              }
              const { data } = response;
              if (data.access_token) {
                dispatch(setAuthorization(data.jwt));
                dispatch(setFormStep1(data.personal_data));
                dispatch(setFormStep2(data.teaching_data));
                router.query.hash = data.access_token;
                router.push(router, undefined, { shallow: true });
                dispatch(setStep(`step${data.step}`));
              } else {
                dispatch(setStep('email'));
              }
            }
          });
        } else {
          setErrorRECAPTCHA(true);
        }
      });
    } else {
      register(data).then((response: Response) => {
        if (!response.error) {
          dataLayerPush({
            event: 'gtm.formSubmit',
            'gtm.elementClasses': document.querySelector('form').classList,
            'gtm.elementUrl': window.location.href,
            label: 'Cadastro de E-mail',
          });
          dispatch(setStep('email'));
        }
      });
    }
  };

  const openTermsOrPolicy = content => {
    setShowModal(true);
    setContent(content);
  };

  const registerAction = (action, withSocial = false) => {
    setValue('social', withSocial, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('lang', i18n.language);
    handleSubmit(action)();
  };

  const onChangeReCAPTCHA = value => {
    setReCAPTCHA({ validate: value });
  };

  const onExpiredReCAPTCHA = () => {
    setReCAPTCHA({ validate: '' });
  };

  useEffect(() => {
    const paramTest = new URL(window.location.href).searchParams.get('test');
    if (paramTest) {
      setIsTest(true);
    }
  }, []);

  return (
    <S.Wrapper recaptcha={errorRECAPTCHA}>
      <Text textType="title" level={1} data-test="register_title">
        {t('register.title')}
      </Text>
      <Form
        layout="vertical"
        initialValues={{
          layout: 'vertical',
        }}
      >
        <Form type="item" label={t('register.create_email')}>
          <Field
            Component={Input}
            control={control}
            errors={errors}
            name="email"
          />
        </Form>
        <Form type="item" label={t('register.mobile_phone')}>
          <Field
            name="mobile_phone"
            control={control}
            Component={Input}
            compProps={{
              type: 'mask',
              mask:
                codeDialMobile === '+(55)'
                  ? `${codeDialMobile} 00 00000-0000`
                  : `${codeDialMobile} [000000000000]`,
              prefix: (
                <CountryPicker
                  className="select-ddi"
                  canTranslate={false}
                  list={flags}
                  callback={changeDialCodeMobile}
                  showI18n={false}
                  size="middle"
                  onClick={e => {
                    // Stop propagation to avoid close the dropdown, because the click event
                    // is propagated to the parent Input.
                    e.stopPropagation();
                  }}
                />
              ),
            }}
            errors={errors}
          />
        </Form>
        <S.Accept>
          <Form valuePropName="checked" type="item">
            <Field
              Component={Checkbox}
              compProps={{
                onChange: e => {
                  setValue('accept', e.target.checked, {
                    shouldValidate: true,
                    shouldDirty: true,
                  });
                },
              }}
              control={control}
              errors={errors}
              name="accept"
              withPlaceholder={false}
            >
              {t('register.read')}{' '}
              <S.TermsAndPoliceButtons
                type="link"
                onClick={() => openTermsOrPolicy(terms)}
              >
                {t('register.terms')}
              </S.TermsAndPoliceButtons>{' '}
              {t('commons.and')}{' '}
              <S.TermsAndPoliceButtons
                type="link"
                onClick={() => openTermsOrPolicy(policy)}
              >
                {t('register.police')}
              </S.TermsAndPoliceButtons>
            </Field>
          </Form>
        </S.Accept>
        <ReCAPTCHA
          sitekey={process.env.NEXT_PUBLIC_RECAPTHA_SITE_KEY}
          onChange={onChangeReCAPTCHA}
          onExpired={onExpiredReCAPTCHA}
          hl={i18n.language === 'pt' ? 'pt-BR' : i18n.language}
          className="reCAPTCHA"
          style={{ display: 'inline-block' }}
        />
        {errorRECAPTCHA && (
          <S.errorRECAPTCHA>{t('error.recaptcha')}</S.errorRECAPTCHA>
        )}
        <Button
          block
          loading={isLoading}
          size="large"
          type="primary"
          onClick={() => registerAction(onSubmit)}
          disabled={!isValid}
          data-test="submit_register_button"
        >
          {t('register.submit')}
        </Button>
      </Form>
      <S.Subtitle>
        <Text>{t('register.subtitle')}</Text>
        <Button
          type="link"
          onClick={() => dispatch(setStep('login'))}
          data-test="link_login"
        >
          {t('register.login')}
        </Button>
      </S.Subtitle>
      <Modal
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={
          <Button onClick={() => setShowModal(false)} type="primary">
            {t('modal.exit')}
          </Button>
        }
      >
        <S.WrapperModal dangerouslySetInnerHTML={{ __html: content }} />
      </Modal>
      {isError && <ErrorBoundary errors={registerError} />}
    </S.Wrapper>
  );
};

export default Register;
